import React, { FC, useMemo } from 'react'
import { ContentContainer } from '../ContentContainer'
import { SectionHeader, SectionTitle } from 'core/Dashboard/styledComponents'
import { AggregatorsContainer } from 'core/Aggregators/styledComponents'
import useFetchPartnerErrorLogs, {
  PartnerErrorLog,
} from 'common/hooks/useFetchPartnerErrorLogs'
import PaginatedTable from '../PaginatedTable'
import { ColumnProps } from 'antd/lib/table'

const PartnerErrorLogs: FC = () => {
  const {
    data,
    setOrder,
    loading,
    setPage,
    setPerPage,
  } = useFetchPartnerErrorLogs()

  const onSortChange = (_: string, order: string | null) => {
    setOrder(order === 'ASC' ? 'ASC' : 'DESC')
  }

  const columns = useMemo<ColumnProps<PartnerErrorLog>[]>(
    () => [
      {
        title: 'Date',
        dataIndex: 'dateAt',
        key: 'dateAt',
        sorter: true,
      },
      {
        path: 'path',
        title: 'Path',
        dataIndex: 'path',
        key: 'path',
      },
      {
        path: 'errorMessage',
        title: 'Error Message',
        dataIndex: 'errorMessage',
        key: 'errorMessage',
      },
    ],
    []
  )

  return (
    <AggregatorsContainer>
      <SectionHeader>
        <SectionTitle>Error Logs</SectionTitle>
      </SectionHeader>

      <br />
      <br />

      <ContentContainer>
        <PaginatedTable
          total={data?.totalCount || 0}
          loading={loading}
          data={data?.data || []}
          columns={columns}
          onPageChange={(e) => setPage(e)}
          onPageSizeChange={(e) => setPerPage(e)}
          currentPage={data?.currentPage || 1}
          pageSize={data?.perPage || 10}
          onSortChange={onSortChange}
          onFilterApplied={(e) => {}}
          rowKey='dateAt'
        />
      </ContentContainer>
    </AggregatorsContainer>
  )
}

export default PartnerErrorLogs

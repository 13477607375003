import styled from 'styled-components'
import { Steps } from 'antd'
const { Step } = Steps

export const Wrapper = styled.div`
  display: flex;
`

export const StepItem = styled(Step)`
  padding-bottom: 10px;
  &&.ant-steps-item-active .ant-steps-item-icon {
    background: ${({ theme }) => theme.colours.primary};
  }
`

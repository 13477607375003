import React, { useState } from 'react'
import { DateTime } from 'luxon';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { Filters } from './styledComponents'

import { Button } from 'common/components/Button'
import { DatePicker } from 'common/components/DatePicker'

import PaginatedTable from 'common/components/PaginatedTable'
import useFetchTopUp from 'common/hooks/useFetchTopUp';
import useDownloadTopUp from 'common/hooks/useDownloadTopUp';
import { DownloadOutlined } from '@ant-design/icons';

dayjs.extend(utc);

enum topUpViewType {
  download = 'download',
  view = 'view'
}

interface TopUpTableProps {
  fetchUrl: string,
  accountId: number | undefined,
  organizationName: string | undefined
  accountCurrency: string | undefined
}

const TopUpTable: React.FC<TopUpTableProps> = ({
  fetchUrl,
  accountId,
  accountCurrency,
  organizationName
}) => {
  const dateFormat = 'YYYY-MM-DD'
  const defaultFromDate = dayjs().utc().subtract(90, 'd').startOf('day').format()
  const defaultToDate = dayjs().utc().endOf('day').format()

  const [fromDate, setFromDate] = useState(defaultFromDate)
  const [toDate, setToDate] = useState(defaultToDate)
  const [currentPage, setPage] = useState(1)
  const [perPage, setPerPage] = useState(10)
  const [sortBy, setSortBy] = useState("created_at")
  const [order, setOrder] = useState("DESC")

  const { data, total, isLoading } = useFetchTopUp(
    `${fetchUrl}/${topUpViewType.view}`,
    currentPage,
    perPage,
    fromDate,
    toDate, sortBy, order 
  )

  const onSortChange = (sortBy: string, order: string | null) => {
    setSortBy(sortBy)    
    if(order)
      setOrder(order)
  }

  const { downloadTopUpCallBack } = useDownloadTopUp(`${fetchUrl}/${topUpViewType.download}`, organizationName)

  const onPageChange = (currentPage: number) => {
    setPage(currentPage)
  }

  //Disable the date toDate which is less than start date or greater than today's date
  const disabledDate = (value: any) =>{
    const endDate = dayjs(value).utc().startOf('day').format()
   // Can not select days before today and today
   return  endDate < fromDate || endDate > dayjs.utc().endOf('day').format();
  }; 

  //Disable the date fromDate which is less than equal to today's date
  const disabledStartDate = (value: any) =>{
    const startDate = dayjs(value).utc().startOf('day').format()   
    return  startDate > dayjs.utc().endOf('day').format();
  }; 

  const onPageSizeChange = (value: number) => {
    setPerPage(value)
    setPage(1)
  }

  const updateToDate = (value: string | undefined) => {
    if (value) {
      // Moment(value).utc().format()
      setToDate(value)
      setPage(1)
    }
  }

  const updateFromDate = (value: string | undefined) => {
    if (value) {
      // Moment(value).utc().format()
      setFromDate(value)
      setPage(1)
    }
  }

  const onFilterApplied = (filterField: string | null, filterValue: string | null) => {
  }

  const columns = [
    {
      title: 'Timestamp',
      dataIndex: 'topUpDate',
      key: 'topUpDate',
      render: (date: string) => {
        return DateTime.fromISO(date).toFormat('yyyy-MM-dd h:mm a')
      },
      width:150,
    },
    {
      title: 'Phaze Admin Name',
      dataIndex: 'adminfirstName',
      key: 'adminfirstName',
      width: 250,
      render: (name: string, data: any) => {
        return data.adminfirstName + ' ' + data.adminlastName
      },
    },
    {
      title: `TopUp Amount (${accountCurrency})`,
      dataIndex: 'topUpAmount',
      key: 'topUpAmount',
      width: 250,
    },
    {
      title: 'Memo',
      dataIndex: 'memo',
      key: 'memo',
      align: 'center' as 'center',
      width: 300,
    }
  ]

  return (
    <>
      <Filters>
        <Button
          type='default'
          shape='round'
          //TO-DO: Update
          //@ts-ignore
          onClick={() => downloadTopUpCallBack(fromDate, toDate)}
        >
          <DownloadOutlined />
          Download CSV
        </Button>
        <DatePicker
          allowClear={false}
          defaultValue={dayjs(fromDate).utc()}
          format={dateFormat}
          disabledDate={disabledStartDate} 
          onChange={(date: any) => updateFromDate(date?.utc().startOf('day').format())}
          data-testid='test-from-date'
        />
        <DatePicker
          allowClear={false}
          defaultValue={dayjs(toDate).utc()}
          format={dateFormat}
          disabledDate={disabledDate} 
          onChange={(date: any) => updateToDate(date?.utc().endOf('day').format())}
          data-testid='test-to-date'
        />
      </Filters>

      <PaginatedTable
        total={total}
        loading={isLoading}
        columns={columns}
        data={data}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        currentPage={currentPage}
        pageSize={perPage}
        onSortChange={onSortChange}
        onFilterApplied={onFilterApplied}
        rowKey='topUpDate'
        displayContainer={true}
      />
    </>
  )
}

export default TopUpTable

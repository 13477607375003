import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import SignIn from './SignIn'
import { RootState } from 'common/store/rootReducer'
import { login } from 'common/store/auth/actions'
import { useNavigate } from 'react-router-dom'

const SignInContainer: React.FC = () => {
  const dispatch = useDispatch()
  const isSubmitting = useSelector((state: RootState) => state.auth.loading)
  const navigate = useNavigate()

  const handleLogin = (username: string, password: string) => {
    dispatch(login(username, password, navigate))
  }
  
  return <SignIn login={handleLogin} isSubmitting={isSubmitting} />
}

export default SignInContainer

import styled from 'styled-components'
import { Input } from 'common/components/Input'

export const StyledInput = styled(Input)`
  margin-bottom: 20px;

  input[disabled] {
    background: transparent;
  }
`

export const Label = styled.label`
  display: block;
  margin: 0px 0px 4px 12px;
`

import React, { Component } from 'react'

import { FieldProps } from 'formik'

import { FormItem } from 'common/components/FormItem'
import { Switch } from 'antd'

interface FieldSwitchProps extends FieldProps {
  label?: string
  placeholder?: string
  icon?: Component
  suffix?: string
  required?: boolean
  type?: string
}
const FieldSwitch: React.FC<FieldSwitchProps> = ({
  form: { touched, errors, setFieldTouched, setFieldValue, values },
  field: { name },
  label,
  type,
  placeholder,
  icon,
  suffix,
  required
}) => {
  const errorMsg = touched[name] && errors[name]

  return (
    <>
      <FormItem
        help={errorMsg ? (errorMsg as React.ReactNode) : undefined}
        validateStatus={errorMsg ? 'error' : undefined}
        label={label}
        htmlFor={name}
        required={required}
      >
        <Switch checked={values[name]}  
                onChange={(e) => setFieldValue(name,e) }
                />
        
      </FormItem>
    </>
  )
}
export default FieldSwitch

import styled from 'styled-components'

export const HistoryContainer = styled.div`
  padding: 35px 56px;
  display: block;
`
export const SectionTitle = styled.div`
  font-size: ${({ theme }) => theme.font.lg};
  font-weight: bold;
`

export const SectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin:10px 0px;
`

export const Filters = styled.div`
  > * {
    margin-right: 10px;
  }
`

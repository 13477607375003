import * as yup from 'yup'
import {
  provinceSchema,
  postalCodeSchema
} from 'common/utils/validationSchemas'

const companySchema = {
  organizationName: yup
    .string()
    .required()
    .label('Company name'),
  addressOne: yup
    .string()
    .required()
    .label('Address 1'),
  addressTwo: yup
    .string()
    .notRequired()
    .label('Address 2'),
  city: yup
    .string()
    .required()
    .label('City'),
  province: provinceSchema,
  postalCode: postalCodeSchema,
  country: yup.string().required(),
  cryptoCompany: yup.boolean(),
  website: yup
    .string()
    .matches(
      /^(https:\/\/www\.)[a-zA-Z0-9-]+\.[a-z]{2,}(\/.*)?$/,
      'Please enter a full website address starting with https://www.example.com'
    )
    .required()
    .label('Website')
}

const userSchema = {
  firstName: yup
    .string()
    .required()
    .label('First Name'),
  lastName: yup
    .string()
    .required()
    .label('Last Name'),
  partnerEmail: yup
    .string()
    .email()
    .required()
    .label('E-mail')
}

const configSchema = {
  prefundedAmountWarningTopupAmount: yup
    .number()
    .required()
    .label('Top Up Warning'),
  prefundedAmountWarningLow: yup
    .number()
    .required()
    .label('Low Amount Warning'),
  partnersCommission: yup
    .number()
    .required()
    .label('Partners Commission'),
  maxDiscount: yup
    .number()
    .required()
    .label('Maximum Discount'),
  fxSpreadPercent: yup
    .number()
    .required()
    .label('Fx Spread Percent'),
  topUpFxSpreadPercent: yup
    .number()
    .required()
    .label('Mobile Top Up Fx Spread Percent'),
  transactionFeeType:  yup.string(),
  transactionFee: yup
    .number()
    .when('transactionFeeType',(transactionFeeType)=>{
      if(transactionFeeType){
        return yup.number().required()
      }
    })
    .label('Transaction Fees'),
  marginOffset: yup
    .number()
    .min(0, 'Min value 0.')
    .max(100, 'Max value 100.')
    .required()
    .label('Margin Offset'),
  topupWarningEmails: yup
    .string()
    .label('E-mail'),
  supportEmail: yup
    .string()
    .label('E-mail'),
  reconcilePartner: yup.boolean().default(false),
  enableRewardsPage: yup.boolean().default(false),
  breakageSplitPercent: yup.number().min(0, 'Min value 0').max(100, 'Max value 100').default(1).required(),
  //transactionExpiryInMonth: yup.number().min(1, 'Min value 1.').max(12, 'Max value 12.')
}

export const addPartnerValidations = yup.object().shape({
  ...companySchema,
  ...userSchema,
  ...configSchema,
  prefundedAmount: yup
    .number()
    .required()
    .label('Initial funding'),
  prefundCurrency: yup
    .string()
    .required()
    .label('Prefund Currency')
})

export const editPartnerValidations = yup.object().shape({
  ...companySchema,
  ...configSchema,
})

export type FormValues = {
  addressOne: string
  addressTwo: string
  city: string
  country: string
  organizationName: string
  province: string
  postalCode: string
  website: string
  cryptoCompany: boolean
  enableRewardsPage: boolean
  reconcilePartner: boolean
  maxDiscount:number
  firstName: string
  lastName: string
  partnerEmail: string
  
  prefundedAmount: number | string | undefined
  prefundCurrency: string | undefined
  partnersCommission: number
  fxSpreadPercent: number
  topUpFxSpreadPercent: number
  marginOffset: number
  transactionFeeType: string
  transactionFee: number
  prefundedAmountWarningTopupAmount: number | string | undefined
  prefundedAmountWarningLow: number | string | undefined
  topupWarningEmails: string
  breakageSplitPercent: number
  transactionExpiryInMonth: number
  supportEmail: string
}

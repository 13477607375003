import React from 'react'
import { useDispatch } from 'react-redux'
import ChangeTemporaryPassword from './ChangeTemporaryPassword'
import { changeTemporaryPassword } from 'common/store/auth/actions'
import { useNavigate } from 'react-router-dom'

const ChangeTemporaryPasswordContainer: React.FC = () => {
  const dispatch = useDispatch()

  const handleChangeTemporaryPassword = (email: string, oldPassword: string, newPassword: string, navigate: ReturnType<typeof useNavigate>) => {
    dispatch(changeTemporaryPassword(email, oldPassword, newPassword, navigate))
  }

  return <ChangeTemporaryPassword changeTemporaryPassword={handleChangeTemporaryPassword} />
}

export default ChangeTemporaryPasswordContainer

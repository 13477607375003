import styled from 'styled-components'
import { Button } from 'antd'

export const TableView = styled.div`
  display: flex;
  flex-direction: column;

  .ant-btn {
    align-self: flex-end;
  }

  .ant-table-column-title {
    font-weight: 600;
  }
`

export const Actions = styled.div`
  a {
    color: ${({ theme }) => theme.colours.primary};
  }

  > span {
    margin: 0 5px;
  }
`

export const ActionButton = styled(Button)`
  padding: 0;
  color: ${({ theme }) => theme.colours.primary};
`

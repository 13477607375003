import React, { useState } from 'react'
import { Modal } from 'antd'
import { Button } from 'common/components/Button'
import { ContentContainer, StyledIcon } from './styledComponents'
import AddProductForm from './AddBrandForm'
import { BrandCountries } from 'common/store/schema/app'
import { useNavigate } from 'react-router-dom'
import { RoutePaths } from 'core/Router/routePaths'
import { PlusOutlined } from '@ant-design/icons'

type AddProductBasedOnTypeProps = {
  brandCountryList : Array<BrandCountries>,
  brandListType: string,
  addProductToTheList : (updatedValue: any) => void
}

const AddProductBasedOnType: React.FC<AddProductBasedOnTypeProps> = ({ brandCountryList, brandListType, addProductToTheList }) => {
  const [openModal, setOpenModal] = useState(false)
  const navigate = useNavigate()

  const buttonName = (brandListType === "exclusion") ? "Add Product to Exclusion List" : "Add Product to Persistent List"

  return (
    <>
      <div>
        <Button
          type='primary'
          shape='round'
          style={{ margin: '5px 0' }}
          onClick={() => {
            if(brandListType === 'exclusion') {
              navigate(RoutePaths.ADD_EXCLUSION_LIST)
            } else {
              navigate(RoutePaths.ADD_PERSISTENT_LIST)
            }
          }}
        >
          <PlusOutlined />
          {buttonName}
        </Button>
      </div>
      <Modal
        visible={openModal}
        footer={null}
        onCancel={() => setOpenModal(false)}
        width={500}
      >
        <ContentContainer>
          <StyledIcon type='PlusOutlined' />
          
          <AddProductForm
            setOpenModal={setOpenModal}
            addProductToTheList = {addProductToTheList}
            brandCountryList = {brandCountryList}
            brandListType = {brandListType}
          />
        </ContentContainer>
      </Modal>
    </>
  )
}

export default AddProductBasedOnType

import { useCallback, useState } from 'react'
import { client } from 'common/api/client'
import { DateTime } from 'luxon'

interface FinanceDetails {
  balance: number;
  transactions: number;
  transactionsSucceeded: number;
  transactionsFailed: number;
  averageTransaction: number;
  totalCommissions: number;
  totalTransactions: number;
  commissionsInSelectedRange: number;
  salesInSelectedRange: number;
  totalSales: number;
  selectedRange: number;
  averageEarnedPercentage: number;
  displayCurrency: string;
  totaltransactionsFailed: number;
  totaltransactionsSucceeded: number;
  updateTime: string;
}

const useFetchDashboard = (url: string) => {
  const dateTime = DateTime.utc()
  const defaultFinanceDetails: FinanceDetails = {
    balance: 0,
    transactions: 0,
    transactionsSucceeded: 0,
    transactionsFailed: 0,
    averageTransaction: 0,
    totalCommissions: 0,
    totalTransactions: 0,
    commissionsInSelectedRange: 0,
    salesInSelectedRange: 0,
    totalSales: 0,
    selectedRange: 0,
    averageEarnedPercentage: 0,
    displayCurrency: '',
    totaltransactionsFailed: 0,
    totaltransactionsSucceeded: 0,
    updateTime: dateTime.toFormat('yyyy-MM-dd h:mma')
  }

  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState<FinanceDetails>(defaultFinanceDetails)
  const [error, setError] = useState(false)

  const fetchData = useCallback(
    (fromDate: string, toDate: string) => {
      console.log(url);
      setError(false)
      setIsLoading(true)
      const fetchData = async () => {
        try {
          const response = await client.get<FinanceDetails>(url, {
            params: {
              fromDate: fromDate,
              toDate: toDate
            }
          })

          setData({
            ...response.data,
            updateTime: dateTime.toFormat('yyyy-MM-dd h:mma')
          })
        } catch (err) {
          setError(true)
        }
        setIsLoading(false)
      }
      fetchData()
    },
    [url]
  )

  return {
    data,
    isLoading,
    error,
    fetchData
  }
}

export default useFetchDashboard

import React, { useEffect, useState } from 'react'
import Cards from '../Cards';
import { brandType, fetchBrandDetails } from '../fetchRedeem';


const Redeem = (props: any) => {

  const [brand,setBrand] = useState<Array<brandType>>([]);
  const [isLoading,setIsLoading] = useState(false);
  const [showBrandDetails] = useState(true);

  useEffect(() => {
    try{
      const fetchData = async () => {
        // You can await here
        const brand: brandType = await fetchBrandDetails(props.match.params.productId)
        console.log(brand)
        setBrand([brand])
        setIsLoading(false)
        // ...
      }
      setIsLoading(true)
      fetchData()
    }catch(e){
      setIsLoading(false)
    }
  }, [props.match.params.productId])

  return (
    <Cards data={brand} showBrandDetails={showBrandDetails} isLoading={isLoading} />
  )
}

export default Redeem

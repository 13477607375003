import React, { useState } from 'react'
import {
  CardsContainer,
  Card,
  CardContent,
  CardLogo,
  CardSubContent,
  CardTitle,
  CardAmount
} from './styledComponents'
import Spin from 'antd/lib/spin'
import { Input } from 'common/components/Input'
import { openNotification } from 'common/components/openNotification'
import { Button } from 'antd'
import { useNavigate } from 'react-router'
import noImageName from '../images/no-image-icon.png';

interface CardsProps {
  data: any[],
  isCardGroup?: boolean
  isLoading?: boolean
  canConfirmPurchase?: boolean
  denominationValue?: number
  clickHandler?: any
  confirmPurchaseHandler?: any
  showBrandDetails?: boolean
}

const Cards: React.FC<CardsProps> = props => {
  let history = useNavigate();
  const [denominationsValue, setDenominationsValue] = useState('')
  
  const setDenominationsValueHandler = (value: string) =>{
    setDenominationsValue(value)
  }

  const handlePurchaseHandler = () =>{
    if(props.data[0].valueRestrictions && Object.keys(props.data[0].valueRestrictions).length > 0){
      if(props.data[0].valueRestrictions.maxVal >= denominationsValue && props.data[0].valueRestrictions.minVal <= denominationsValue)
      history(`/redeem/brand-details/${props.data[0].productId}/${denominationsValue}`)
      else
        openNotification('Please select a valid denominations','error')
      }else if(props.data[0].denominations.length > 0){
        if(props.data[0].denominations.includes(+denominationsValue))
        history(`/redeem/brand-details/${props.data[0].productId}/${denominationsValue}`)
        else
          openNotification('Please select a valid denominations','error')
      }else{
        openNotification('Please select a valid denominations','error')
      }
  }

  const startOverHandler = () =>{
    history(`/redeem`)
  }

  return (
    <Spin spinning={props.isLoading} delay={300}>
      <CardsContainer isCardGroup={props.isCardGroup}>
        {props.data.map((card, key) => {
          return (
            <Card key={key} onClick={()=> props.showBrandDetails || props.canConfirmPurchase ? ()=>false :  props.clickHandler(card.productId)}>
               {!props.showBrandDetails && !props.canConfirmPurchase ?(
                <CardLogo>
                  <img src={card.productImage ? card.productImage: noImageName} alt={card.brandName} />
                </CardLogo>) : (<></>)}
              <CardContent>
              {props.showBrandDetails || props.canConfirmPurchase ?(
                <CardLogo>
                  <img src={card.productImage ? card.productImage: noImageName} alt={card.brandName} />
                </CardLogo>) : (<></>)}
                 <CardTitle><strong>Brand:</strong> {card.brandName}</CardTitle>
                <CardAmount><strong>Currency:</strong> {card.currency}</CardAmount>
                
                {props.showBrandDetails || props.canConfirmPurchase ? 
                  (<><CardAmount><strong>Product Description:</strong> {card.productDescription}</CardAmount>
                      <CardSubContent><strong>Product Terms And Condition: </strong>
                        <div dangerouslySetInnerHTML={{ __html: card.termsAndConditions }} />
                      </CardSubContent>
                    </>) : (<p></p>)}

                {!props.canConfirmPurchase && card.denominations.length > 0 ? 
                  (<CardAmount><strong>Denominations:</strong> {card.denominations.join(', ')}</CardAmount>) : (<></>)} 
                {!props.canConfirmPurchase && card.valueRestrictions && Object.keys(card.valueRestrictions).length > 0 ? 
                  (<><CardAmount><strong>Min Purchase Price:</strong> {card.valueRestrictions.minVal}</CardAmount>
                    <CardAmount><strong>Max Purchase Price:</strong> {card.valueRestrictions.maxVal}</CardAmount></>) : (<p></p>)}


                {props.showBrandDetails && card.valueRestrictions && Object.keys(card.valueRestrictions).length > 0 ? 
                    (<CardAmount><strong>Denominations:</strong> <Input
                      style={{'width':'25%', marginTop: '10px'}}
                      title='Denominations'
                      placeholder='Enter the amount you would like to purchase'
                      value={denominationsValue}
                      onChange={ (e) =>setDenominationsValueHandler(e.target.value)}
                    /></CardAmount>) : (<></>)}

                {props.showBrandDetails && card.denominations.length > 0 ? 
                    (<CardAmount>Denominations: 
                      {card.denominations.map((denomination: number,key: number) => {
                        return (<Button
                            key={key}
                            onClick={() => setDenominationsValueHandler(denomination.toString())}
                            size="small"
                            style={{ width: 90, margin: '3px' }} >
                            {denomination}
                          </Button>) }) } </CardAmount>) : (<></>)}
                      

                {props.showBrandDetails ? (<CardAmount>
                      <Button
                          onClick={() => startOverHandler()}
                          size="large"
                            style={{ width: 170, marginLeft: '1%', marginTop: '10px' }} >
                          Back
                      </Button>
                        <Button
                          type="primary"
                          onClick={() => handlePurchaseHandler()}
                          size="large"
                            style={{ width: 90, marginLeft: '15%', marginTop: '10px' }} >
                          Purchase
                      </Button>
                      
                      </CardAmount>) : (<></>)}

                {props.canConfirmPurchase ? (<CardAmount>You are purchasing the <b>INR: {props.denominationValue}</b> gift card.</CardAmount>): (<></>)} 

                {props.canConfirmPurchase ? (<CardAmount><Button
                          type="primary"
                          onClick={() => props.confirmPurchaseHandler()}
                          size="large"
                            style={{ width: 170, marginLeft: '15%', marginTop: '10px' }} >
                          Confirm Purchase
                      </Button><Button
                          onClick={() => startOverHandler()}
                          size="large"
                            style={{ width: 170, marginLeft: '1%', marginTop: '10px' }} >
                          Start Over
                      </Button></CardAmount>) : (<></>)}
              </CardContent>
            </Card>
          )
        })}
      </CardsContainer>
    </Spin>
  )
}

export default Cards

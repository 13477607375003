const roundToDecimalPlaces = (value: number, places: number): number => {
  if (value < 0) {
    throw new Error('Value argument must be a positive number.')
  }
  const precisionFactor = Math.pow(10, places)
  const valueWithPrecision = value * precisionFactor
  const roundedValueWithPrecision = Math.round(valueWithPrecision)
  const roundedValue = roundedValueWithPrecision / precisionFactor
  return Number(roundedValue.toFixed(places))
}

export default roundToDecimalPlaces

import { Tabs } from 'antd'
import ViewContainer from 'common/components/ViewContainer'
import React, { FC } from 'react'
import Keys from './pages/Keys/Keys'
import PartnerAdmins from './pages/PartnerAdmins'

interface ISettingsProps {
  fetchConfigurations: () => Promise<void>
  apiKey: string
  requestSecret: string
}

const Settings: FC<ISettingsProps> = ({
  apiKey,
  fetchConfigurations,
  requestSecret,
}) => {
  return (
    <ViewContainer title='Settings'>
      <Tabs animated={false} defaultActiveKey='1'>
        <Tabs.TabPane key='1' tab='API keys'>
          <Keys
            apiKey={apiKey}
            fetchConfigurations={fetchConfigurations}
            requestSecret={requestSecret}
          />
        </Tabs.TabPane>
        <Tabs.TabPane key='2' tab='Admins'>
          <PartnerAdmins />
        </Tabs.TabPane>
      </Tabs>
    </ViewContainer>
  )
}

export default Settings

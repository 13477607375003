import React, { FC, useRef, useState } from 'react'
import SunEditor from 'suneditor-react'
import SunEditorCore from 'suneditor/src/lib/core'
import 'suneditor/dist/css/suneditor.min.css'
import './styles.css'
import plugins from 'suneditor/src/plugins'
import useLocalStorage from 'hooks/useLocalStorage'
import { Button, Input } from 'antd'
import { client } from 'common/api/client'
import { openNotification } from 'common/components/openNotification'
import MultiSelect from '../MultiSelect'
import MyList from '../List'
import useFetchTemplates from '../hooks/useFetchTemplates'
import useFetchEmails from '../hooks/useFetchEmails'
import { ContentContainer } from 'common/components/ContentContainer'
import isEmail from 'helpers/isEmail'
import { SaveOutlined } from '@ant-design/icons'

const EmailEditor: FC = () => {
  const [body, setBody] = useLocalStorage('emailEditorContent', '')
  const [selectedEmails, setSelectedEmails] = useState<string[]>([])
  const [name, setName] = useState<string>('')
  const [subject, setSubject] = useState<string>('')

  const [loadingBroadcast, setLoadingBroadcast] = useState<boolean>(false)

  const [selectedTemplateId, setSelectedTemplateId] = useState<number>()

  const {
    templates,
    createNewTemplate,
    loading,
    deleteTemplate,
    updateTemplate
  } = useFetchTemplates()

  const { emails, loading: emailsLoading } = useFetchEmails()

  const editor = useRef<SunEditorCore>()

  // The sunEditor parameter will be set to the core suneditor instance when this function is called
  const getSunEditorInstance = (sunEditor: SunEditorCore) => {
    editor.current = sunEditor
  }

  const clearEditor = () => {
    if (editor.current) editor.current.setContents('')
  }

  const handleEmailsSelectChange = (value: string[]) => {
    // Split the input by a comma and trim spaces
    const inputEmails = value
      .flatMap(email => email.split(','))
      .map(email => email.trim())

    //@ts-ignore
    // only distinct emails
    let emails = [...new Set(inputEmails)]

    emails = emails.filter(isEmail)

    // If there is an invalid email, show an error
    if (emails.length !== inputEmails.length) {
      const difference = inputEmails.length - emails.length

      openNotification(
        `${difference} email address(es) were not added. Either they are invalid or duplicates.`,
        'error'
      )
    }

    setSelectedEmails(emails)
  }

  const handleSelectAll = () => {
    setSelectedEmails(emails.map(e => e.support_email))
  }

  const handleUnselectAll = () => {
    setSelectedEmails([])
  }

  const onSelectTemplate = (id: number) => {
    setSelectedTemplateId(id)
    const template = templates.find(t => t.id === id)!

    setName(template.name)
    setSubject(template.subject)
    setBody(template.body)
  }

  const broadcastHandler = () => {
    setLoadingBroadcast(true)
    client
      .post('/broadcast-emails', {
        emails: selectedEmails,
        body,
        subject,
        templateName: name
      })
      .then(r => {
        openNotification('Email sent successfully', 'success')
      })
      .catch(e => {
        const error = e?.response?.data?.error || e.message

        openNotification(error, 'error')
      })
      .finally(() => {
        setLoadingBroadcast(false)
      })
  }

  return (
    <div className='editor-container'>
      <SunEditor
        getSunEditorInstance={getSunEditorInstance}
        placeholder='Type email content here...'
        autoFocus
        lang='en'
        height='600'
        width='70%'
        onChange={setBody}
        defaultValue={body}
        setContents={body}
        setOptions={{
          plugins: plugins,
          buttonList: [
            ['undo', 'redo'],
            ['font', 'fontSize', 'formatBlock'],
            ['paragraphStyle', 'blockquote'],
            [
              'bold',
              'underline',
              'italic',
              'strike',
              'subscript',
              'superscript'
            ],
            ['fontColor', 'hiliteColor', 'textStyle'],
            ['removeFormat'],
            '/', // Line break
            ['outdent', 'indent'],
            ['align', 'horizontalRule', 'list', 'lineHeight'],
            ['table', 'link', 'image', 'video', 'audio' /** ,'math' */], // You must add the 'katex' library at options to use the 'math' plugin. // You must add the "imageGalleryUrl".
            /** ['imageGallery'] */ ['fullScreen', 'showBlocks', 'codeView'],
            ['preview', 'print'],
            ['save', 'template']
          ]
        }}
      />

      <ContentContainer className='right-side'>
        <div>
          <h2>Select users</h2>
          <div className='multi-selector-container'>
            <MultiSelect
              value={selectedEmails}
              handleChange={handleEmailsSelectChange}
              emails={emails}
              loading={emailsLoading}
            />
          </div>
          <div className='buttons-container'>
            <Button onClick={handleSelectAll}>Select all</Button>
            <Button onClick={handleUnselectAll}>Clear input</Button>
            <Button disabled={loadingBroadcast} onClick={broadcastHandler}>
              Send
            </Button>
          </div>
        </div>

        <div className='save-template'>
          <h2>Save template</h2>
          <Input
            onChange={e => setName(e.target.value)}
            value={name}
            placeholder='Template name'
            disabled={loading}
          />
          <Input
            style={{ marginTop: 2 }}
            onChange={e => setSubject(e.target.value)}
            value={subject}
            placeholder='Subject'
            disabled={loading}
          />
          <Button
            onClick={() => {
              if (selectedTemplateId) {
                // update template
                updateTemplate({
                  body,
                  id: selectedTemplateId,
                  name,
                  subject
                })
              } else {
                createNewTemplate({ body, name, subject })
                  .then(() => {
                    setName('')
                    setSubject('')
                    clearEditor()
                  })
                  .catch(e => {})
              }
            }}
            style={{ marginTop: 4 }}
          >
            <SaveOutlined type='save' /> {selectedTemplateId ? 'Update' : 'Save'}
          </Button>

          <Button
            style={{ marginLeft: 4 }}
            disabled={!selectedTemplateId}
            onClick={() => {
              if (selectedTemplateId) {
                setSelectedTemplateId(undefined)
                setName('')
                setSubject('')
                clearEditor()
              }
            }}
          >
            Create New
          </Button>
        </div>

        <div style={{ marginTop: 10 }} className='templates'>
          <h2>Templates</h2>
          <MyList
            deleteHandler={deleteTemplate}
            onSelect={onSelectTemplate}
            loading={loading}
            data={templates}
          />
        </div>
      </ContentContainer>
    </div>
  )
}

export default EmailEditor

import React, { useEffect, useState } from 'react'
import { Form } from 'antd'
import { Formik, Field, FormikHelpers } from 'formik'
import validationsSchema, { FormValues } from './validationsSchema'
import { StyledSubmitButton } from './styledComponents'
import { FieldProps } from 'formik'
import FieldSelect from 'common/components/FieldSelect'
import { openNotification } from 'common/components/openNotification'
import { client } from 'common/api/client'
import { URL_BRAND_LIST_PER_COUNTRY } from 'common/api/paths'
import { BrandCountries } from 'common/store/schema/app'
import { RightOutlined } from '@ant-design/icons'

type AddProductListProps = {
  brandCountryList: Array<BrandCountries>,
  setOpenModal: (openModal: boolean) => void,
  brandListType: string,
  addProductToTheList : (updatedValue: any) => void
}

type brandType = {
  name: string,
  id: string,
  currencyCode: string
}

type ProductListResponse = {
  data: {
    brands: brandType[]
  }
}

const AddProductForm: React.FC<AddProductListProps> = ({
  setOpenModal, brandCountryList, brandListType, addProductToTheList
}) => {
  const initialValues: FormValues = {
    country: '',
    productId: '',
    mapped: (brandListType === "exclusion") ? false : true,
    clientFacingProductId : null
  }

  const enableReinitialize = true
  const exclusionMappedproduct = (brandListType === "exclusion") ? "Select Exclusion Product": "Select Supplier Facing Product"
  const exclusionMappedCountry = (brandListType === "exclusion") ? "Select Exclusion Country": "Select Supplier Facing Country"

  const [countryName,setCountry] = useState<string>('')
  const [clientFacingCountryName,setClientFacingCountry] = useState<string>('')
  const [productList, setProductList] = useState<brandType[]>([])
  const [clientFacingProductList, setClientFacingProductList] = useState<brandType[]>([])

  useEffect(() => {

    const fetchProducts = async (setValue : React.Dispatch<React.SetStateAction<brandType[]>> ,countryValue: string) =>{
        try{
          const productListData = await client.get(URL_BRAND_LIST_PER_COUNTRY(countryValue)) as ProductListResponse
          setValue(productListData.data.brands)
        }catch(err: any){
          openNotification (err.response.data.error, 'error')
        }
    }

    if(countryName){
      fetchProducts(setProductList,countryName)
    }
    if(clientFacingCountryName){
      fetchProducts(setClientFacingProductList,clientFacingCountryName)
    }

  },[countryName,clientFacingCountryName])

  //Add the product to the exclusion list
  const onSubmit = async (values: FormValues, { resetForm }: FormikHelpers<FormValues>) => {  
    const updatedValues = {
      productId : values.productId,
      clientFacingProductId : values.clientFacingProductId
    }

    addProductToTheList(updatedValues)
    
    try {
      // await client.post(URL_ADD_EXCLUSION_MAPPED, updatedValues)
      resetForm();
      setOpenModal(false)
    } catch (err: any) {
      openNotification(err.response.data.error, 'error')
    }
  }

  //Fetch the products
  const getProducts = async(country: any) =>{
      setCountry(country)      
  }

  //Fetch the products
  const getClientFacingProducts = async(country: any) =>{
      setClientFacingCountry(country)      
  }

  const uniqueBrandCountryList = brandCountryList.filter((ctylist, index, self) =>
    index === self.findIndex((t) => (
      t.countryName === ctylist.countryName
    ))
  )

  //Generate key value for select options
  const getCountryOptions = uniqueBrandCountryList.map((cty : BrandCountries) => ({
    key: cty.countryName,
    value: cty.countryName
  }))

  //Generate key value for select options
  const getBrandsOptions = productList.map((product: brandType) => ({
    key: product.name + " ( " + product.id + " )",
    value: product.id.toString()
  }))

  //Generate key value for Cleint Facing Brand List
  const getClientBrandsOptions = clientFacingProductList.map((product: brandType) => ({
    key: product.name + " ( " + product.id + " )",
    value: product.id.toString()
  }))

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationsSchema}
      onSubmit={onSubmit}
      enableReinitialize={enableReinitialize}
    >
      {({ handleSubmit, errors, touched }) => (
        <Form onFinish={handleSubmit} id='addProductForm'>

          {brandListType === "mapped" ? (
          <>
              <Field name='clientFacingCountryList' as='select'>
                {(fieldProps: FieldProps) => {
                  return (
                    <FieldSelect
                      label='Client Facing Country List'
                      placeholder='Please select'
                      options={getCountryOptions}
                      getValue={getClientFacingProducts}
                      required
                      {...fieldProps}
                    />
                  )
                }}
            </Field>
            <Field name='clientFacingProductId' as='select'>
                {(fieldProps: FieldProps) => {
                  return (
                    <FieldSelect
                      label='Client Facing Product List'
                      placeholder='Please select'
                      options={getClientBrandsOptions}
                      required
                      {...fieldProps}
                    />
                  )
                }}
            </Field>
          </>
          ) : (<></>)}

          <Field name='country' as='select'>
              {(fieldProps: FieldProps) => {
                return (
                  <FieldSelect
                    label={exclusionMappedCountry}
                    placeholder='Please select'
                    options={getCountryOptions}
                    getValue={getProducts}
                    required
                    {...fieldProps}
                  />
                )
              }}
          </Field>
          <Field name='productId' as='select'>
              {(fieldProps: FieldProps) => {
                return (
                  <FieldSelect
                    label={exclusionMappedproduct}
                    placeholder='Please select'
                    options={getBrandsOptions}
                    required
                    {...fieldProps}
                  />
                )
              }}
          </Field>

          <StyledSubmitButton
            form='addProductForm'
            type='primary'
            shape='round'
            htmlType='submit'
            disabled={
              Object.keys(errors).length > 0 ||
              Object.keys(touched).length === 0
            }
          >
            Add Product
            <RightOutlined />
          </StyledSubmitButton>
        </Form>
      )}
    </Formik>
  )
}

export default AddProductForm

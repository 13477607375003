import React from 'react'

import {Modal, Table } from 'antd'
import { ContentContainer } from 'common/components/ContentContainer'
import { Actions, ActionButton } from './styledComponents'

const { confirm } = Modal

interface WebHooksProps {
  id: number | null,
  webhook: Array<any> | undefined
  organisationName: string
  deleteWebHook: (webhookId: number,accountId: number ) => void
}
const WebHooks: React.FC<WebHooksProps> = ({
  id, webhook, organisationName, deleteWebHook
}) => {

  console.log(webhook)

  //Delete the user and provide confirmation
  const showDeleteUserConfirmation = (webhookId: number, accountId: number) => {
    confirm({
      title: 'Are you sure you want to delete the webhook?',
      content: `Organisation Name: ${organisationName}`,
      okText: 'Yes',
      onOk() {
        deleteWebHook(webhookId,accountId)
      }
    })
  }

  const columns = [
    {
      title: 'API-Key',
      dataIndex: 'apiKey',
      key: 'apiKey',
      width:100,
    },
    {
      title: 'Authorisation Header',
      dataIndex: 'authorizationHeaderName',
      key: 'authorizationHeaderName',
      width:100,
    },
    {
      title: 'URL',
      dataIndex: 'url',
      key: 'url'
    },
    {
      title: 'CreatedAt',
      dataIndex: 'created_at',
      key: 'created_at'
    },
    {
      title: 'Action',
      key: 'action',
      align: 'center' as 'center',
      render: (text: string,record : any) => {
        console.log(record)
        return (
          <Actions>
              <>
                <ActionButton
                  onClick={() => showDeleteUserConfirmation(record.id,record.accountId)}
                  type='link'
                >
                  Delete
                </ActionButton>
              </>
          
          </Actions>
        )
      }
    }    
  ]

  /* const [currentPage, setPage] = useState(1)
  const [perPage, setPerPage] = useState(10)
  const onPageChange = (currentPage: number) => {
    setPage(currentPage)
  }

  const onPageSizeChange = (value: number) => {
    setPerPage(value)
    setPage(1)
  }

  const onSortChange = (sortBy: string, order: string | null) => {
  }

  const onFilterApplied = (actionName: string | null) => {
    console.log(actionName);
  } */


  return (
    <>
      <ContentContainer>
                <Table
                   columns={columns}
                   dataSource={webhook || []}
                   rowKey= 'id'
                   pagination={false} 
                />
      </ContentContainer>
    </>
  )
}
export default WebHooks

import React from 'react'
import { Container, MainSection } from './styledComponents'
import { Layout } from 'antd'

import { RoutePaths } from 'core/Router/routePaths'
import { Routes, Route } from 'react-router-dom'

import Redeem from 'core/Redeem'
import EditBrandDetails from 'core/Redeem/CardDetails'
import ConfirmBrandPurchase from 'core/Redeem/ConfirmBrandPurchase'
import PurchaseStatus from 'core/Redeem/ConfirmBrandPurchase/Purchase'
import Issues from 'core/Issues'
const { Content } = Layout

const PageContainer: React.FC = () => {
  return (
    <Container>
      <Layout>
        <MainSection>
          <Content>
          <Routes>
          <Route
            path={`${RoutePaths.REDEEM_BRAND_PUCRAHSE_STATUS}/:orderId`}
            element={<PurchaseStatus />}
          />
          <Route
            path={`${RoutePaths.REDEEM_BRAND_DETAILS}/:productId/:denomination`}
            element={<ConfirmBrandPurchase />}
          />
          <Route
            path={`${RoutePaths.REDEEM_BRAND_DETAILS}/:productId`}
            element={<EditBrandDetails />}
          />
          <Route path={RoutePaths.REDEEM} element={<Redeem />} />
          <Route path={RoutePaths.ISSUES} element={<Issues />} />
        </Routes>
          </Content>
        </MainSection>
      </Layout>
    </Container>
  )
}

export default PageContainer

import styled from 'styled-components'
import AntTabs from 'antd/lib/tabs'
import { ContentContainer } from 'common/components/ContentContainer'
import FinancialDetails from './FinancialDetails'
const { TabPane: AntTabPane } = AntTabs

export const FinancialDetailsWithLayout = styled(FinancialDetails)`
  margin: 80px 0 40px;
`

export const Tabs = styled(AntTabs)`
  .ant-tabs-bar {
    margin-bottom: 0;
    border-bottom-color: transparent;
  }

  &.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab {
    border: none;
    border-radius: 0;
    margin-right: 5px;
    font-weight: 600;
  }

  &.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active {
    color: ${props => props.theme.colours.primary};
    border-bottom: 2px solid ${props => props.theme.colours.primary};
  }
`

export const TabPane = styled(AntTabPane)``

export const TabContent = styled(ContentContainer)`
  border-top-left-radius: 0;
`

import styled from 'styled-components'
import { DatePicker as DatePickerBase } from 'antd'

export const DatePicker = styled(DatePickerBase)`
  width: 130px;
  input {
    border-radius: 25px;
  }
`

export const DatePickerInput = styled(DatePickerBase)`
  width: 100%;
  input {
    padding-left: 30px;
    border-radius: 25px;
  }

  i {
    left: 12px;
    color: ${({ theme }) => theme.colours.primary};
  }
`

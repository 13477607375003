import styled from 'styled-components'

export const Filters = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 30px;

  > * {
    margin: 0 0 0 10px;
  }
`

import styled from 'styled-components'
import { Button } from 'antd'

export const AggregatorsContainer = styled.div`
  padding: 35px 56px;
  display: block;
`
export const SectionTitle = styled.div`
  font-size: ${({ theme }) => theme.font.lg};
  font-weight: bold;
`

export const SectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin:10px 0px;
`

export const Filters = styled.div`
  > * {
    margin-right: 10px;
  }
`

export const Actions = styled.div`
  a {
    color: ${({ theme }) => theme.colours.primary};
  }

  > span {
    margin: 0 5px;
  }
`

export const ActionButton = styled(Button)`
  padding: 0;
  color: ${({ theme }) => theme.colours.primary};
`

import { client } from 'common/api/client'
import { useEffect, useState } from 'react'

type Response = {
  emails: {
    organization_name: string
    support_email: string
  }[]
  count: number
}

const useFetchEmails = () => {
  const [emails, setEmails] = useState<Response['emails']>([])
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)

  const fetch = async () => {
    setLoading(true)

    try {
      const { data } = await client.get<Response>('/partner-emails')

      setEmails(data.emails)
    } catch (error) {
      // @ts-ignore
      setError(error.response?.data?.message || error.message)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetch()
  }, [])

  return {
    emails,
    error,
    loading,
    refetch: fetch
  }
}

export default useFetchEmails

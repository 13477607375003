import styled from 'styled-components'

export const Row = styled.div`
  display: flex;
  height: 100%;
  flex-wrap: wrap;

  > div {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
  }
`
export const LoginLeftPane = styled.div`
  background: ${({ theme }) => theme.colours.bgLight};
  flex-grow: 1;
  min-width: 300px;
`

export const LoginLeftContainer = styled.div`
  max-width: 282px;
  min-height: 70vh;
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const LoginRightPane = styled.div`
  background: ${({ theme }) => theme.colours.bgDark};
  flex-grow: 3;
`
export const Logo = styled.img``

export const PasswordDescription = styled.p`
  font-size: ${({ theme }) => theme.font.sm};
`

export const FormTitle = styled.h1`
  font-size: ${({ theme }) => theme.font.xl};
  line-height: 1.17;
`

export const FormDescription = styled.p`
  font-size: ${({ theme }) => theme.font.sm};
  line-height: 1.67;
  margin-bottom: 20%;
`

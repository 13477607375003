import React from 'react'
import Card from './Card'
import Loader from './Card/Loader'
import useIssues from './fetchIssues'
import './styles.css'

const Issues = () => {
  const { data, loading } = useIssues()

  return (
    // background gray
    <div
      style={{
        backgroundColor: '#f2f2f2',
      }}
    >
      <div className='container'>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: 30,
          }}
        >
          <img src='/static/media/logo-positive.c6bb1366.svg' alt='' />
        </div>
        <h2 className='title'>Giftcard Issues</h2>
        <p
          className='color-primary'
          style={{ fontWeight: 'bold', fontSize: '1rem' }}
        >
          This page shows the list of all the Giftcards which have issues being
          processed.
        </p>

        <div className='card-container'>
          {loading ? (
            <>
              <Loader />
              <Loader />
              <Loader />
            </>
          ) : !!data.length ? (
            data.map((issue, _idx) => <Card key={_idx} issue={issue} />)
          ) : (
            <h2
              style={{ textAlign: 'center', fontSize: 24, fontWeight: 'bold' }}
            >
              No issues found
            </h2>
          )}
        </div>
      </div>
    </div>
  )
}

export default Issues

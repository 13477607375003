import React from 'react'

import { Menu, Icon, StyledLink } from './styledComponents'

import { RoutePaths } from 'core/Router/routePaths'

const SuperNavigation: React.FC = () => {
  return (
    <Menu mode='inline'>
      <Menu.Item key='home'>
        <StyledLink to={RoutePaths.DASHBOARD}>
          <Icon type='HomeOutlined' /> <span>Dashboard</span>
        </StyledLink>
      </Menu.Item>

      <Menu.Item key='partners'>
        <StyledLink to={RoutePaths.PARTNERS}>
          <Icon type='DeploymentUnitOutlined' /> <span>Partners</span>
        </StyledLink>
      </Menu.Item>
      <Menu.Item key='team'>
        <StyledLink to={RoutePaths.TEAM}>
          <Icon type='UserOutlined' /> <span>Team</span>
        </StyledLink>
      </Menu.Item>
      <Menu.Item key='history'>
        <StyledLink to={RoutePaths.HISTORY}>
          <Icon type='HistoryOutlined' /> <span>History</span>
        </StyledLink>
      </Menu.Item>
      <Menu.Item key='Transaction'>
        <StyledLink to={RoutePaths.SUPERADMINTRANSACTION}>
          <Icon type='SwapOutlined' /> <span>Transaction</span>
        </StyledLink>
      </Menu.Item>
      <Menu.Item key='Exclusion List'>
        <StyledLink to={RoutePaths.EXCLUSION}>
          <Icon type='ScissorOutlined' /> <span>Exclusion List</span>
        </StyledLink>
      </Menu.Item>
      <Menu.Item key='Mapped List'>
        <StyledLink to={RoutePaths.MAPPED_PRODUCTS}>
          <Icon type='ShrinkOutlined' /> <span>Pesistent List</span>
        </StyledLink>
      </Menu.Item>
      <Menu.Item key='Aggregators'>
        <StyledLink to={RoutePaths.AGGREGATORS}>
          <Icon type='ApartmentOutlined' /> <span>Aggregators</span>
        </StyledLink>
      </Menu.Item>
      <Menu.Item key='Brands Delta'>
        <StyledLink to={RoutePaths.CATALOG_AUDIT}>
          <Icon type='AuditOutlined' /> <span>Catalog Updates</span>
        </StyledLink>
      </Menu.Item>
      <Menu.Item key='Error Logs'>
        <StyledLink to={RoutePaths.ERRORLOGS}>
          <Icon type='FileOutlined' /> <span>Error Logs</span>
        </StyledLink>
      </Menu.Item>
      <Menu.Item key='Broadcast'>
        <StyledLink to={RoutePaths.BROADCAST}>
          <Icon type='MailOutlined' /> <span>Broadcast</span>
        </StyledLink>
      </Menu.Item>
    </Menu>
  )
}

export default SuperNavigation

import { Modal } from 'antd'
import React, { FC } from 'react'
import { BroadcastHistory } from '../hooks/useBroadcastHistories'
import EmailList from './EmailList'

interface IHistoryDetailsModalProps {
  selectedItem?: number
  data?: BroadcastHistory
  closeModal: () => void
}

const HistoryDetailsModal: FC<IHistoryDetailsModalProps> = ({
  selectedItem,
  closeModal,
  data,
}) => {
  const successEmails = data?.sentToEmails?.split(',').filter((e) => e)
  const failedEmails = data?.failedEmails?.split(',').filter((e) => e)

  return (
    <Modal
      visible={!!selectedItem}
      footer={null}
      onCancel={closeModal}
      width={'40vw'}
    >
      <h2>Template Name: {data?.templateName}</h2>

      <h3>Subject: {data?.subject}</h3>

      <h3 style={{ color: 'green' }}>Success: {successEmails?.length || 0}</h3>
      <h3 style={{ color: 'red' }}>Failed: {failedEmails?.length || 0}</h3>

      <h3>Body</h3>
      <div
        style={{ border: '1px solid #ccc', padding: 10 }}
        /** this makes it vulnerable to XSS attacks
         *  but since only super admins can add data
         *  and they are trusted, it's ok
         */
        dangerouslySetInnerHTML={{
          __html: data?.body || '',
        }}
      />

      {!!successEmails?.length && (
        <div style={{ marginTop: 10 }}>
          <h3>Successfully sent to the following email addresses</h3>
          <EmailList emails={successEmails || []} />
        </div>
      )}

      {!!failedEmails?.length && (
        <div style={{ marginTop: 10 }}>
          <h3>Failed to send to the following email addresses</h3>

          <EmailList emails={failedEmails || []} />
        </div>
      )}
    </Modal>
  )
}

export default HistoryDetailsModal

import * as React from 'react';
import Cell from './cell';
import './dataTable.css';

interface DataTableProps {
  headings: Array<string | number>,
  rows: Array<any>,
  deleteLastTier : () => void
  onChangeText : (columnIndex: number, rowIndex: number, value: string) => void
}


const DataTable: React.FC<DataTableProps> = ({
  headings,
  rows,
  deleteLastTier,
  onChangeText
}) => {

  const renderHeadingRow = (_cell : any, cellIndex : any) => {

    return (
      <Cell
        columnIndex = {cellIndex} 
        rowIndex={-1}
        key={`heading-${cellIndex}`}
        content={headings[cellIndex]}
        header={true}
        onDelete ={deleteLastTier}
        onChangeText = {onChangeText}
        inputDisabled={false} 
      />
    )
  };
  
  const renderRow = (_row : any, rowIndex : number) => {
    const rowKeys = Object.keys(_row)

    return (
      <tr key={`row-${rowIndex}`}>
        {rowKeys.map((key: string)=>{
          const showFields = (key !== "pricingTier")  ? true : false
          const showNum = (key === "pricingTier") ? rowIndex + 1  : ''

          let showDelete = rows.length > 2 && ((rows.length-1) === rowIndex) && (key === "monthlyFee" ) ? true : false
          const rowTableIndex = _row["pricingTier"]
          const cellIndex= (key === "pricingTier") ? 0 : ((key === "minimumTxnAmount") ? 1 : ((key === "maximumTxnAmount") ? 2 : 3 ))
          const inputDisabled = ( (cellIndex === 1 ) || (cellIndex === 2 && rowTableIndex === rows.length)) ? true : false

          let showDeleteButton
          if(showDelete){
            showDeleteButton = (<Cell
              columnIndex = {cellIndex}
              inputDisabled={false} 
              rowIndex={rowTableIndex}
              onDelete = {deleteLastTier}
              onChangeText = {onChangeText}
              showDelete ={showDelete}
              showFields = {showFields}
              key={`${rowTableIndex}-${cellIndex}-delete`}
              content={key + ' ' + _row[key]}
            />)
          }

          let content = (key === "pricingTier") ? (key + ' ' + showNum) : _row[key]

            return (
                <>
                  <Cell
                    showFields = {showFields}
                    inputDisabled={inputDisabled}
                    columnIndex = {cellIndex} 
                    rowIndex={rowTableIndex}
                    onChangeText = {onChangeText}
                    onDelete = {deleteLastTier}
                    key={`${rowTableIndex}-${cellIndex}`}
                    content={content}
                  />
                  {showDeleteButton}
                </>
              )
            })
        }
      </tr>
    )
  };


    const theadMarkup = (
      <tr key="heading">
        {headings.map(renderHeadingRow)}
      </tr>
    );

    const tbodyMarkup = rows.map(renderRow);
  
    return (
      <table className="Table">
        <thead>{theadMarkup}</thead>
        <tbody>{tbodyMarkup}</tbody>
      </table>
    );
}

export default DataTable
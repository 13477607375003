import React from 'react'
import { FinanceDetails, isSuperAdminFinanceDetails, SuperAdminFinanceDetails } from 'common/store/schema/dashboard'
import { theme } from 'common/styles/theme'
import getCurrencyToLocale from 'common/utils/getCurrencyToLocale'
import { RoleType } from 'common/store/auth/slice'
import { BarChartOutlined, DollarOutlined, LineChartOutlined, SwapOutlined, WalletOutlined } from '@ant-design/icons'

export const getPartnerConfig = (data: FinanceDetails, reconcilePartner: boolean | null = false) => {
  if (!data) return []

  return [
    {
      title: 'Your Balance',
      amount: renderCurrency(data.balance, data.displayCurrency),
      icon: <WalletOutlined />,
      bgColour: theme.colours.boxColour1,
      subContent: (
        <>
          {reconcilePartner &&
            <p>Withheld Balance: {data.holdingBalance?.toFixed(2)} {data.displayCurrency}</p>
          }
        </>
      )
    },
    {
      title: 'Total sales',
      amount: renderCurrency(data.salesInSelectedRange, data.displayCurrency),
      icon: <LineChartOutlined />,
      bgColour: theme.colours.boxColour5
    },
    {
      title: 'Commissions',
      amount: renderCurrency(data.totalRevenueInRange, data.displayCurrency),
      icon: <DollarOutlined />,
      bgColour: theme.colours.boxColour4
    },
    {
      title: 'Transactions',
      amount: data.transactions,
      subContent: (
        <>
          <p>Succeeded: {data.transactionsSucceeded}</p>
          <p>Failed: {data.transactionsFailed}</p>
        </>
      ),
      icon: <SwapOutlined />,
      bgColour: theme.colours.boxColour2
    },
    {
      title: 'Avg. transaction',
      amount: data.averageTransaction
        ? renderCurrency(data.averageTransaction, data.displayCurrency)
        : 'n/a',
      icon: <BarChartOutlined />,
      bgColour: theme.colours.boxColour3
    }
  ]
}

export const getSuperConfig = (data: SuperAdminFinanceDetails) => {
  if (!data) return []

  return [
    {
      title: 'Balance (all wallets)',
      amount: renderCurrency(data.balance, data.displayCurrency),
      icon: <WalletOutlined />,
      bgColour: theme.colours.boxColour1,
      subContent: (
        <>
          <p>Withheld Balance: {data.holdingBalance?.toFixed(2)} CAD</p>
        </>
      )
    },
    {
      title: 'Total sales',
      amount: renderCurrency(data.salesInSelectedRange, data.displayCurrency),
      icon: <LineChartOutlined />,
      bgColour: theme.colours.boxColour5
    },
    {
      title: 'Revenue Earned',
      amount: renderCurrency(
        data.totalRevenueInRange,
        data.displayCurrency
      ),
      icon: <DollarOutlined />,
      bgColour: theme.colours.boxColour4,
      subContent: (
        <>
          <p>Commissions: {renderCurrency(data.totalCommisionsInRange,data.displayCurrency)}</p>
          <p>Delta Discount Profit: {renderCurrency(data.totalDeltaDiscountProfitInRange,data.displayCurrency)}</p>
          <p>Fx revenue: {renderCurrency(data.totalFxInRange,data.displayCurrency)}</p>
          <p>Margin Offset: {renderCurrency(data.totalMarginOffsetInRange,data.displayCurrency)}</p>
          <p>Breakage Split Profit: {renderCurrency(data.totalBrakageSplitInRange,data.displayCurrency)}</p>
        </>
      )
    },
    {
      title: 'Transactions',
      amount: data.transactions,
      subContent: (
        <>
          <p>Succeeded: {data.transactionsSucceeded}</p>
          <p>Failed: {data.transactionsFailed}</p>
          <p>Cancelled: {data.transactionsCancelled}</p>
        </>
      ),
      icon: <SwapOutlined />,
      bgColour: theme.colours.boxColour2
    },
    {
      title: 'Avg. transaction',
      amount: data.averageTransaction
        ? renderCurrency(data.averageTransaction, data.displayCurrency)
        : 'n/a',
      icon: <BarChartOutlined />,
      bgColour: theme.colours.boxColour3
    }
  ]
}

export const getOverview = (data: FinanceDetails | SuperAdminFinanceDetails, role: string) => {
  if (!data) return []

  return [
    {
      title: 'Lifetime Sales',
      amount: renderCurrency(data.totalSales, data.displayCurrency),
      icon: <LineChartOutlined />,
      bgColour: theme.colours.boxColour5
    },
    {
      title: 'Total Revenue Earned',
      amount: renderCurrency(data.totalRevenue, data.displayCurrency),
      icon: <DollarOutlined />,
      bgColour: theme.colours.boxColour4,
      subContent: isSuperAdminFinanceDetails(data) ? (
        <>
          <p>Commissions: {renderCurrency(data.totalCommisions,data.displayCurrency)}</p>
          <p>Fx revenue: {renderCurrency(data.totalFx,data.displayCurrency)}</p>
          <p>Margin Offset: {renderCurrency(data.totalMarginOffset,data.displayCurrency)}</p>
          <p>Delta Discount Profit: {renderCurrency(data.totalDeltaDiscountProfit,data.displayCurrency)}</p>
          <p>Breakage Split Profit: {renderCurrency(data.totalBreakageSplit,data.displayCurrency)}</p>
        </>
      ) : null
    },
    {
      title: 'Total # of Transactions',
      amount: data.totalTransactions,
      subContent: (
        <>
          <p>
            Succeeded:{' '}
            {data.totaltransactionsSucceeded
              ? data.totaltransactionsSucceeded
              : 'n/a'}
          </p>
          <p>
            Failed:{' '}
            {data.totaltransactionsFailed
              ? data.totaltransactionsFailed
              : 'n/a'}
          </p>
          {role === 'super_admin' || role === RoleType.PhazeAdmin && (
            <p>
              Cancelled:{' '}
              {data.totalTransactionsCancelled
                ? data.totalTransactionsCancelled
                : 'n/a'}
            </p>
          )}
        </>
      ),
      icon: <SwapOutlined />,
      bgColour: theme.colours.boxColour2
    },
    {
      title: 'Avg. % Earned',
      amount: data.averageEarnedPercentage
        ? data.averageEarnedPercentage.toPrecision(2) + ' %'
        : 'n/a',
      icon: <BarChartOutlined />,
      bgColour: theme.colours.boxColour3
    }
  ]
}

export const getSalesOverview = (
  data: FinanceDetails,
  isCustomRange?: boolean
) => {
  if (!data) return []
  return [
    {
      title: 'Total sales',
      amount: renderCurrency(data.totalSales, data.displayCurrency),
      bgColour: theme.colours.boxColour2
    },
    {
      title: isCustomRange ? 'This range' : 'Last 30 days',
      amount: renderCurrency(data.salesInSelectedRange, data.displayCurrency),
      bgColour: theme.colours.boxColour3
    }
  ]
}

const renderCurrency = (amount: number, currency = 'CAD') => {
  const splitAmount = getCurrencyToLocale(amount, currency).split('.')

  if (splitAmount[1] && splitAmount[1] !== '00') {
    return (
      <>
        {splitAmount[0]}
        <span>.{splitAmount[1]}</span>
      </>
    )
  } else {
    return splitAmount[0]
  }
}

import React from 'react'
import CopyToClipboardBase from 'react-copy-to-clipboard'
import { openNotification } from 'common/components/openNotification'
import { Button } from 'common/components/Button'
import { CopyOutlined } from '@ant-design/icons'

interface CopyToClipboardProps {
  successMessage?: string
  text: string
}
const CopyToClipboard: React.FC<CopyToClipboardProps> = ({
  text,
  successMessage = 'Copied to clipboard'
}) => {
  return (
    <>
      <CopyToClipboardBase
        text={text}
        onCopy={() => openNotification(successMessage, 'success')}
      >
        <Button type='dashed' style={{ margin: '0 0 0 10px' }} compact>
          Copy
          
          <CopyOutlined />

        </Button>
      </CopyToClipboardBase>
    </>
  )
}
export default CopyToClipboard

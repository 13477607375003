import React, { useState } from 'react'
import { Modal } from 'antd'
import { Button } from 'common/components/Button'

import { StyledName, ContentContainer, StyledIcon } from './styledComponents'
import AddFundsForm from './AddFundsForm'
import { Account } from 'common/store/schema/accounts'
import { PlusOutlined } from '@ant-design/icons'

type AddFundsProps = {
  account: Account
  fetchAccountById: (id: number) => Promise<void>
}
const AddFunds: React.FC<AddFundsProps> = ({ account, fetchAccountById }) => {
  const [openModal, setOpenModal] = useState(false)

  return (
    <>
      <div>
        <Button
          type='primary'
          shape='round'
          style={{ margin: '5px 0' }}
          onClick={() => setOpenModal(true)}
        >
          <PlusOutlined />
          Top Up
        </Button>
      </div>
      <Modal
        visible={openModal}
        footer={null}
        onCancel={() => setOpenModal(false)}
        width={384}
      >
        <ContentContainer>
          <StyledIcon type='WarningOutlined' />
          <h2>
            Updating funds to <StyledName>{account.organizationName}</StyledName>{' '}
            account.
          </h2>

          <AddFundsForm
            accountName={account.organizationName}
            accountId={account.id}
            fetchAccountById={fetchAccountById}
            setOpenModal={setOpenModal}
          />
        </ContentContainer>
      </Modal>
    </>
  )
}

export default AddFunds

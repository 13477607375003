import React from 'react'
import ContentLoader from 'react-content-loader'

const Loader = () => {
  return (
    <ContentLoader
      style={{
        height: 300,
        width: '100%',
      }}
      speed={2}
      width={600}
      height={200}
      viewBox='0 0 600 300'
      backgroundColor='#f3f3f3'
      foregroundColor='#ecebeb'
    >
      <rect x='0' y='0' rx='0' ry='0' width='600' height='300' />
    </ContentLoader>
  )
}

export default Loader

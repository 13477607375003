import { Tabs } from 'antd'
import ViewContainer from 'common/components/ViewContainer'
import React, { FC, useState } from 'react'

import 'suneditor/dist/css/suneditor.min.css'
import BroadcastHistory from './BroadcastHistory'
import EmailEditor from './EmailEditor'

const Broadcast: FC = () => {
  const [activeTab, setActiveTab] = useState('1')

  const onChangeHandler = (key: string) => {
    setActiveTab(key)
  }

  return (
    <ViewContainer title={activeTab === '1' ? 'Email Editor' : 'History'}>
      <Tabs onChange={onChangeHandler} animated={false}>
        <Tabs.TabPane tab='Email Editor' key='1'>
          <EmailEditor />
        </Tabs.TabPane>
        <Tabs.TabPane tab='History' key='2'>
          <BroadcastHistory />
        </Tabs.TabPane>
      </Tabs>
    </ViewContainer>
  )
}

export default Broadcast

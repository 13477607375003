export const baseColours = {
  white: '#ffffff',
  grey1: '#f5f5f5',
  grey2: '#bfbfbf',
  grey3: '#595959',
  grey4: '#262626',
  grey5: '#d9d9d9',
  grey6: '#8c8c8c',
  blue1: '#0093cc',
  blue2: '#02addc',
  blue3: '#005a9f',
  blue4: '#1e2bb7',
  blue5: '#02c1e8',
  green1: '#0fccc4',
  green2: '#0aa9a2',
  red1: '#fff0f1',
  red2: '#ff9eb5',
  red3: '#f52248',
  orange1: '#fa8c16',
  orange2: '#faad14'
}

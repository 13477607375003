import { connect } from 'react-redux'
import Settings from './Settings'
import { fetchConfigurations } from 'common/store/partnerAdmin/actions'
import { RootState } from 'common/store/rootReducer'
const mapDispatchToProps = {
  fetchConfigurations,
}

const mapStateToProps = (state: RootState) => {
  //const configurationAcc = accountSelectors.getCurrentAccount(state)

  return {
    apiKey: state.partnerAdmin.apiKey,
    requestSecret: state.partnerAdmin.requestSecret,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Settings)

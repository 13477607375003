import moment from 'moment'
import React, { FC } from 'react'
import { PhazeIssue } from '../fetchIssues'

interface ICardProps {
  issue: PhazeIssue
}

const Card: FC<ICardProps> = ({ issue }) => {
  return (
    <div className='card'>
      <div
        className={`card-header ${
          issue.dateResolved ? 'card-header-green' : 'card-header-blue'
        }`}
      >
        {issue.title}
      </div>
      <div className='card-content'>
        {/* <h2 className='card-title'>Product Name: {issue} </h2> */}

        <p
          style={{
            color: issue.dateResolved ? 'green' : '',
          }}
        >
          <strong>{issue.dateResolved ? 'Resolved' : 'Identified'}</strong> -{' '}
          {issue.dateResolved
            ? 'This issue has been resolved'
            : issue.description}
        </p>

        <p>
          <strong>Product Name</strong>: {issue.productName}
        </p>

        <p>
          <strong>Country ISO Code</strong>: {issue.country}
        </p>

        <p>
          <strong>Affected Product ids:</strong>
          {issue.ids.map((id) => (
            <p style={{ margin: '0.6rem 0' }} key={id}>
              {id}
            </p>
          ))}
        </p>

        <p>
          <strong>Date: </strong>
          {moment(issue.date).format('DD MMM, YYYY hh:mm A')}
        </p>
      </div>
    </div>
  )
}

export default Card

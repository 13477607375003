import styled from 'styled-components'

export const BalanceHeading = styled.h2`
  color: ${props => props.theme.colours.text};
  font-size: 20px;
  margin: 0.5em 0;
  line-height: 1.4;
`

export const Balance = styled.span`
  display: block;
  color: ${props => props.theme.colours.link};
  font-size: 30px;
  line-height: ${38 / 30}em;
`

export const CommissionsHeading = styled.h2`
  color: ${props => props.theme.colours.heading};
  font-size: 14px;
  margin: ${3 / 14}em 0;
  line-height: 1.57;
`

export const Commissions = styled.span`
  display: block;
  color: ${props => props.theme.colours.textDark};
  font-size: 20px;
  line-height: 1.4;
`

export const Detail = styled.span`
  display: block;
  font-size: 12px;
  line-height: 1.67;
`

export const Group = styled.div`
  margin: 20px 0;
`

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { partnerAccount } from '../schema/partnerAdmin'

type PartnerAdminState = {
   apiKey: string
   requestSecret: string
   loading: boolean
   error: null | string
  }

  type PartnerAdminPayload = {
    partnerAccount: partnerAccount[]
    apiKey: string,
    requestSecret: string,
    totalCount: number
  }

  let initialState: PartnerAdminState = {
    apiKey: "",
    requestSecret: "",
    loading: false,
    error: null
  }

  function startLoading(state: PartnerAdminState) {
    state.loading = true
    state.error = null
  }
  
  function loadingFailed(state: PartnerAdminState, action: PayloadAction<string>) {
    state.loading = false
    state.error = action.payload
  }

const partnerAdminSlice = createSlice({
    name: 'partnerAdmin',
    initialState,
    reducers: { 
        fetchConfigurationRequest: startLoading,
        fetchConfigurationFailure: loadingFailed,
        fetchConfigurationSuccess(state, action: PayloadAction<PartnerAdminPayload>) {
            state.loading = false
            state.error = null
            state.apiKey=action.payload.apiKey
            state.requestSecret=action.payload.requestSecret
          }
    }
})

export const {
    fetchConfigurationRequest,
    fetchConfigurationFailure,
    fetchConfigurationSuccess
  } = partnerAdminSlice.actions
  
export const partnerAdminReducer = partnerAdminSlice.reducer
  
import { Dispatch } from '@reduxjs/toolkit'
import {
  fetchBrandForSelectedTypeRequest,
  fetchBrandForSelectedTypeSuccess,
  fetchBrandForSelectedTypeFailure,
  deleteProductRequest,
  deleteProductSuccess,
  deleteProductFailure,
  addProductRequest,
  addProductSuccess,
  addProductFailure
} from './slice'
import { client } from 'common/api/client'
import { URL_ADD_EXCLUSION_MAPPED, URL_EXCLUSION_MAPPED_LIST } from 'common/api/paths'
import { openNotification } from 'common/components/openNotification'
import { store } from '..'
import { ProductListType } from '../schema/productList'

type FetchResponse = {
  result: ProductListType[]
  totalCount: number
}

export const fetchBrandForSelectedType = (
  brandListType : string,
  currentPage?: number,
  perPage?: number,
  searchParams?: string,
) => async (dispatch: Dispatch) => {
  try {
    dispatch(fetchBrandForSelectedTypeRequest())
    const params = {
      currentPage,
      perPage,
      searchParams: searchParams || '',
      brandListType
    }
    const res = await client.get<FetchResponse>(URL_EXCLUSION_MAPPED_LIST, { params })
    dispatch(fetchBrandForSelectedTypeSuccess(res.data))
  } catch (err: any) {
    const errorMessage = err.response?.data.error || err.toString()
    dispatch(fetchBrandForSelectedTypeFailure(errorMessage))
  }
}

export const deleteProduct = (id : string,brandListType: string,currentPage?: number,perPage?: number,searchParams?: string )  => async (dispatch: Dispatch) => {
  try {
    dispatch(deleteProductRequest())
    await client.delete(`${URL_EXCLUSION_MAPPED_LIST}/${id}/${brandListType}`)
    dispatch(deleteProductSuccess())
    store.dispatch(fetchBrandForSelectedType(brandListType,currentPage,perPage,searchParams))
    openNotification('Product has been deleted', 'success')
  } catch (err: any) {
    const errorMessage = err.response?.data.error || err.toString()
    dispatch(deleteProductFailure(errorMessage))
    openNotification(errorMessage, 'error')
  }
}

export const addProduct = (updatedValues : any,brandListType: string,currentPage?: number,perPage?: number,searchParams?: string )  => async (dispatch: Dispatch) => {
  try {
    dispatch(addProductRequest())
    await client.post(URL_ADD_EXCLUSION_MAPPED, updatedValues)
    dispatch(addProductSuccess())
    store.dispatch(fetchBrandForSelectedType(brandListType,currentPage,perPage,searchParams))
    openNotification('Added to product to the list', 'success')
  } catch (err: any) {
    const errorMessage = err.response?.data.error || err.toString()
    dispatch(addProductFailure(errorMessage))
    openNotification(errorMessage, 'error')
  }
}


import React, { FC } from 'react'
import { Button, List } from 'antd'
import { Template } from '../hooks/useFetchTemplates'
import { DeleteOutlined } from '@ant-design/icons'

interface IMyListProps {
  data: Template[]
  loading: boolean

  onSelect: (id: number) => void
  deleteHandler: (id: number) => void
}

const MyList: FC<IMyListProps> = ({
  data,
  loading,
  deleteHandler,
  onSelect,
}) => {
  return (
    <List
      itemLayout='horizontal'
      dataSource={data}
      loading={loading}
      renderItem={(item) => (
        <List.Item>
          <List.Item.Meta
            // title={<a href='https://ant.design'>{item.name}</a>}
            title={item.name}
          />
          <div style={{ display: 'flex', placeItems: 'center', gap: 3 }}>
            <Button onClick={() => onSelect(item.id)}>Select</Button>
            <Button onClick={() => deleteHandler(item.id)} danger>
              <DeleteOutlined />
            </Button>
          </div>
        </List.Item>
      )}
    />
  )
}

export default MyList

import styled from 'styled-components';
import { Input as AntInput } from 'antd';
import { DefaultTheme } from 'styled-components'; // Import the default theme type

interface StyledProps {
  theme: DefaultTheme; 
}
export const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;  /* Stack label and input vertically */
  margin-bottom: 15px;
`;

export const FieldLabel = styled.label<{ required?: boolean }>`
  display: block;
  margin: 0px 0px 4px 12px;

  &:before {
    content: '${(props) => (props.required ? "* " : "")}';
    color: red;
  }
`;


export const FieldInputWrapper = styled.div`
  display: flex;
  flex-direction: column;  /* Ensure input remains in column layout */

  input {
    padding-left: 30px; /* Adjust padding if needed based on icon size */
  }
`;

export const IconWrapper = styled.div`
  position: absolute;
  left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ErrorLogs } from '../schema/errorLogs'

type ErrorLogState = {
  errorLogsArray: ErrorLogs[]
  account: Array<any>
  totalErrorLogs: number
  loading: boolean
  error: null | string
}

type ErrorLogsPayload = {
  data: ErrorLogs[],
  totalCount: number,
  account: Array<any>
}

let initialState: ErrorLogState = {
  errorLogsArray: [],
  account: [],
  totalErrorLogs: 0,
  loading: false,
  error: null
}

function startLoading(state: ErrorLogState) {
  state.loading = true
  state.error = null
}

function loadingFailed(state: ErrorLogState, action: PayloadAction<string>) {
  state.loading = false
  state.error = action.payload
}

const errorLogsSlice = createSlice({
  name: 'errorLogs',
  initialState,
  reducers: {
    fetchErrorLogsRequest: startLoading,
    fetchErrorLogsSuccess(state, action: PayloadAction<ErrorLogsPayload>) {
      state.loading = false
      state.error = null
      state.errorLogsArray = action.payload.data
      state.totalErrorLogs = action.payload.totalCount
      state.account = action.payload.account
    },
    fetchErrorLogsFailure: loadingFailed,
  }
})


export const {
  fetchErrorLogsRequest,
  fetchErrorLogsSuccess,
  fetchErrorLogsFailure
} = errorLogsSlice.actions

export const errorLogReducer = errorLogsSlice.reducer

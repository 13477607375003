import React from 'react'
import { DateTime } from 'luxon'

import { Form } from 'antd'

import { Formik, Field } from 'formik'
import validationsSchema, { FormValues } from './validationsSchema'

import { theme } from 'common/styles/theme'

import FieldInput from 'common/components/FieldInput'
import FieldCheckbox from 'common/components/FieldCheckbox'
import { StyledSubmitButton } from './styledComponents'

import { client } from 'common/api/client'
import { URL_ADD_FUNDS } from 'common/api/paths'
import { openNotification } from 'common/components/openNotification'
import { castToNumber } from 'common/utils/common'
import { DollarOutlined, RightOutlined } from '@ant-design/icons'

type AddFundsProps = {
  accountName: string
  accountId: number
  fetchAccountById: (id: number) => void
  setOpenModal: (openModal: boolean) => void
}
const AddFundsForm: React.FC<AddFundsProps> = ({
  accountName,
  accountId,
  fetchAccountById,
  setOpenModal
}) => {
  const initialValues = {
    amount: undefined,
    memo: undefined,
    confirm: false
  }

  const onSubmit = async (values: FormValues, { resetForm }: any) => {
    const updatedValues = {
      amount: castToNumber(values.amount),
      memo: values.memo,
      date: DateTime.utc() //.format()
    }

    if(updatedValues.date > DateTime.utc()){
      openNotification('Date can not be in future', 'error')
      return
    }
    
    try {
      await client.put(URL_ADD_FUNDS(accountId), updatedValues)
      await fetchAccountById(accountId)
      resetForm();
      setOpenModal(false)
      openNotification('Funds have been updated', 'success')
    } catch (err: any) {
      openNotification(err.response.data.error, 'error')
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationsSchema}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({ handleSubmit, errors, touched }) => (
        <Form onFinish={handleSubmit} id='addFundsForm'>
          <Field
            label='Value'
            name='amount'
            placeholder={'1,000,000.00'}
            component={FieldInput}
            icon={
              <DollarOutlined style={{ color: theme.colours.primary }} />
            }
            required
          />
          <Field
            label='Memo'
            name='memo'
            component={FieldInput}
            required
          />
          <Field
            name='confirm'
            component={FieldCheckbox}
            required
            text={`I, hereby, confirm that the funds have cleared ${accountName} account on the date above.`}
          />

          <StyledSubmitButton
            form='addFundsForm'
            type='primary'
            shape='round'
            htmlType='submit'
            disabled={
              Object.keys(errors).length > 0 ||
              Object.keys(touched).length === 0
            }
          >
            Update Funds
            <RightOutlined />
          </StyledSubmitButton>
        </Form>
      )}
    </Formik>
  )
}

export default AddFundsForm

import * as yup from 'yup'
export default yup.object().shape({
  amount: yup
    .number()
    .required()
    .label('Value'),
  memo: yup
    .string()
    .required()
    .label('Memo'),
  confirm: yup
    .bool()
    .test(
      'confirm',
      'Please check this box if you want to proceed',
      value => value === true
    )
    .required('Please check this box if you want to proceed')
})

export type FormValues = {
  amount: number | string | undefined
  memo : string | undefined
}

import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import BrandListBasedOnType from './BrandListBasedOnType';
import { fetchBrandForSelectedType, deleteProduct, addProduct } from 'common/store/brandListFromType/actions';
import { ProductListSelectors } from 'common/store/brandListFromType/slice';
import { RootState } from 'common/store/rootReducer';
import { appSelectors } from 'common/store/app/slice';

type BrandsFromTypeProps = {
  brandListType: string
}

const BrandsFromType: React.FC<BrandsFromTypeProps> = ({ brandListType }) => {  
  const brandList = useSelector((state: RootState) => ProductListSelectors.getProductList(state));
  const isLoading = useSelector((state: RootState) => ProductListSelectors.getIsLoading(state));
  const totalList = useSelector((state: RootState) => ProductListSelectors.getTotalCount(state));
  const brandCountryList = useSelector((state: RootState) => appSelectors.getBrandCountries(state));

  return (
    <BrandListBasedOnType 
      brandList={brandList} 
      isLoading={isLoading} 
      totalList={totalList} 
      brandCountryList={brandCountryList}
      fetchBrandForSelectedType={fetchBrandForSelectedType}
      deleteProduct={deleteProduct}
      addProduct={addProduct}
      brandListType={brandListType}
    />
  );
}

export default BrandsFromType;

import styled from 'styled-components'
import { Alert } from 'antd'
import { theme } from 'common/styles/theme'

const { colours } = theme

export const StyledAlert = styled(Alert)`
  visibility: visible;
  width: 400px;

  && {
    background: ${({ type }) => type === 'error' && colours.errorBg};
    border-color: ${({ type }) => type === 'error' && colours.errorBorder};
    border-radius: 0px;
  }

  .ant-alert-message,
  .ant-alert-close-icon i {
    color: ${colours.text};
  }
`

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Brands } from '../schema/brands'

type BrandsState = {
  loading: boolean
  error: string | null
  brands: Brands[]
  totalCount: number
}

const initialState: BrandsState = {
  loading: false,
  error: null,
  brands: [],
  totalCount: 0
}

type BrandPayload = {
  result: Brands[],
  totalCount: number
}

function startLoading(state: BrandsState) {
  state.loading = true
  state.error = null
}

function loadingFailed(state: BrandsState, action: PayloadAction<string>) {
  state.loading = false
  state.error = action.payload
}

const brandSlice = createSlice({
  name: 'brands',
  initialState,
  reducers: {
    fetchBrandsDeltaRequest: startLoading,
    fetchBrandsDeltaFailure: loadingFailed,
    fetchBrandsDeltaSuccess(state, action: PayloadAction<BrandPayload>) {
      state.loading = false
      state.error = null
      state.brands = action.payload.result
      state.totalCount = action.payload.totalCount
    }
  }
})

export const {
  fetchBrandsDeltaRequest,
  fetchBrandsDeltaFailure,
  fetchBrandsDeltaSuccess,
} = brandSlice.actions

export const brandsDeltaReducer = brandSlice.reducer

import React from 'react';
import styled from 'styled-components';
import * as Icons from '@ant-design/icons';
import { IconBase } from '../IconBase';


export const InputIcon = styled(IconBase)`
  && {
    color: ${({ theme }) => theme.colours.inputIcon};
  }
`;

import styled from 'styled-components'
import { Checkbox as CheckboxBase } from 'antd'

export const Checkbox = styled(CheckboxBase)`
  display: flex;
  align-items: flex-start;
  line-height: 1.5;

  .ant-checkbox {
    top: 3px;
  }

  .ant-checkbox-checked::after {
    border-color: ${({ theme }) => theme.colours.primary};
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${({ theme }) => theme.colours.primary};
    border-color: ${({ theme }) => theme.colours.primary};
  }
`

import React, { useEffect } from 'react'
import { Row, Col } from 'antd'

import TeamMemberForm from '../TeamMemberForm'
import ViewContainer from 'common/components/ViewContainer'
import { User } from 'common/store/schema/team'
import qs from 'query-string'
import { useLocation } from 'react-router-dom'

interface EditTeamMemberProps {
  onSubmit: (userId: number, values: object) => void
  user: User | undefined
  fetchUsers: ( currentPage: number,
    perPage: number,
    sortBy: string,
    order: string,
    filterByPartner: string | null,
    filterByName: string | null) => void
}
const EditTeamMember: React.FC<EditTeamMemberProps> = ({
  onSubmit,
  user,
  fetchUsers,
}) => {
  const location = useLocation()
  var data = qs.parse(location.search)

  let currentPage = 1 ,perPage = 10 , sortBy = 'name', order = "ASC",filterByPartner = "", filterByName = ""

  if(data.currentPage) currentPage = +data.currentPage
  if(data.perPage) currentPage = +data.perPage
  if(data.sortBy) sortBy = data.sortBy as string
  if(data.order) order = data.order as string
  if(data.filterByPartner) filterByPartner = data.filterByPartner as string
  if(data.filterByName) filterByName = data.filterByName as string
  

  useEffect(() => {
    //Fetch the users if it is missing
    if (!user) {
      fetchUsers(currentPage,perPage,sortBy,order,filterByPartner,filterByName)
    }
  }, [fetchUsers, user,currentPage,perPage,sortBy,order,filterByPartner,filterByName])

  
  const initialValues = {
    firstName: user?.firstName,
    lastName: user?.lastName,
    email: user?.email,
    role: user?.role,
    accountId: user?.accountId || undefined
  }

  const handleEdit = (values: object) => {
    if (user) onSubmit(user.id, values)
  }

  return (
    <ViewContainer title={'Edit Team Member'} subPath='Team'>
      <Row justify='space-between'>
        <Col span={16}>
          <TeamMemberForm initialValues={initialValues} onSubmit={handleEdit} />
        </Col>
      </Row>
    </ViewContainer>
  )
}

export default EditTeamMember

import { Dispatch } from '@reduxjs/toolkit'
import { openNotification } from 'common/components/openNotification'
import {
  fetchErrorLogsRequest,
  fetchErrorLogsSuccess,
  fetchErrorLogsFailure
} from './slice'
import { client } from 'common/api/client'
import { URL_GET_ERROR_LOGS } from 'common/api/paths'

export const fetchErrorLogs = (
  currentPage: number,
  perPage: number,
  accountId?: number,
  order: 'ASC' | 'DESC' = 'DESC'
) => async (dispatch: Dispatch) => {
  try {
    dispatch(fetchErrorLogsRequest())

    const res = await client.get<any>(URL_GET_ERROR_LOGS, {
      params: { currentPage, perPage, accountId, order }
    })

    dispatch(fetchErrorLogsSuccess(res.data))
  } catch (err: any) {
    const errorMessage = err.response?.data.error || err.toString()

    dispatch(fetchErrorLogsFailure(errorMessage))

    openNotification(errorMessage, 'error')
  }
}

import React from 'react'

import { Input } from 'common/components/Input'
import { Button } from 'common/components/Button'
import { PlusOutlined } from '@ant-design/icons'

interface CellKeyprops {
  content : string | number,
  header ?: boolean,
  key : string
  showFields ?: boolean,
  showDelete ?: boolean
  onDelete : () => void,
  columnIndex : number,
  inputDisabled : boolean,
  rowIndex : number,
  onChangeText : (columnIndex : number, rowIndex: number,value : string) => void
}
const Cell: React.FC<CellKeyprops> = ({
  content,
  header,
  showFields,
  showDelete,
  onDelete,
  onChangeText,
  columnIndex,
  inputDisabled,
  rowIndex
}) => {

  const data = !showFields ? (<>{content}</>) : 
  (<Input
    disabled={inputDisabled}
    value={content}
    onChange={(e)=>onChangeText(columnIndex,rowIndex,e.target.value)}
    style={{ width: "100%" }}
  />)

  if(showDelete){
    return (<td className="Cell">
                <Button
                    type='primary'
                    shape='round'
                    style={{ margin: '5px 0' }}
                    onClick={() => onDelete()}
                  >
                      <PlusOutlined />
                      Delete
                  </Button>
            </td> );
  }else{
    const cellMarkup = header ? (
      <th className="Cell Cell-header">
        {content}
      </th>
    ) : (
      <td className="Cell">
        {data}
      </td> 
    );
  
    return (cellMarkup);
  }

}
export default Cell

import * as yup from 'yup'

export default yup.object().shape({
  login: yup
    .string()
    .max(256)
    .email()
    .required()
    .label('Login'),
  password: yup
    .string()
    .required()
    .label('Password')
})

import { client } from 'common/api/client'
import { useEffect, useState } from 'react'

export type PhazeIssue = {
  title: string
  description: string
  ids: string[]
  productName: string
  country: string
  date: number
  dateResolved: string | null
}

const useIssues = () => {
  const [data, setData] = useState<PhazeIssue[]>([])
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(true)

  const fetch = () => {
    setLoading(true)
    client
      .get('/status/rewards')
      .then((r: any) => { //TO-DO: Update
        setData(r.data)
      })
      .catch(e => {
        setError(e.response?.data?.message || 'Error fetching data')
      })
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    fetch()
  }, [])

  return { data, error, loading, refetch: fetch }
}

export default useIssues

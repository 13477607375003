import React from 'react'
import { Button, Row, Switch, Modal } from 'antd'
import { Input } from 'common/components/Input'
import { StyledInput, Label } from './styledComponents'
import CopyToClipboard from 'common/components/CopyToClipBoard'
import { Mode, setApiKey, setSecret, setAccountDisable } from 'common/store/accounts/actions'
import { CloseOutlined, PlusOutlined } from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'common/store/rootReducer'
import { accountSelectors } from 'common/store/accounts/slice'

const { confirm } = Modal

const AccessKeys: React.FC = () => {
  const dispatch = useDispatch()
  
  // Using useSelector to get the state from Redux
  const { id, disabled, organizationName, apiKey, requestSecret } = useSelector((state: RootState) => {
    const currentAccountId = state.accounts.currentAccountId
    if (!currentAccountId) {
      return {
        id: null,
        disabled: true,
        organizationName: '',
        apiKey: '',
        requestSecret: ''
      }
    }
    return accountSelectors.getCurrentAccount(state)
  })

  const showConfirmationRevoke = (id: number, name: string, setKey: (id: number, mode: Mode) => void) => {
    confirm({
      title: `Are you sure you want to revoke  ${name}?`,
      content: '',
      okText: 'Yes',
      onOk() {
        if(id){
          setKey(id, Mode.Revoke)
        }
      }
    })
  }

  const getInputAddon = (
    key: any,
    setKey: (id: number, mode: Mode) => void,
    name: string
  ) => {
    return key ? (
      <Button
        style={{ width: 175 }}
        danger
        onClick={id !== null ? () => showConfirmationRevoke(id,name, setKey) : undefined}
      >
        Revoke {name}
        <CloseOutlined />
      </Button>
    ) : (
      <Button
        style={{ width: 175 }}
        onClick={id !== null ? () => setKey(id, Mode.Generate) : undefined}
      >
        Generate {name}
        <PlusOutlined />
      </Button>
    )
  }

  const showConfirmation = (id: number, disable: boolean) => {
    let content = `Are you sure you would like to temporarily disable ${organizationName}? This would mean all requests from the given partner will be denied?`
    if(!disable)
      content = `Are you sure you would like to enable ${organizationName}? This would mean all requests from the given partner will be granted?`

    confirm({
      title: '',
      content: content,
      okText: 'Yes',
      onOk() {
        if(id){
          const mode = disable ? Mode.DisableAccount : Mode.EnableAccount
          dispatch(setAccountDisable(id, mode))
        }
      }
    })
  }

  const onChange = (checked : boolean, id: number | null) =>{
    if(id)
      showConfirmation(id,checked)
  }

  const secretAddon = getInputAddon(requestSecret, (id: number, mode: Mode) => dispatch(setSecret(id, mode)), 'Secret')
  const apiAddon = getInputAddon(apiKey, (id: number, mode: Mode) => dispatch(setApiKey(id, mode)), 'API Key')

  return (
    <>
      <Row align='middle' style={{ marginBottom : '20px' }}>
        <Input.Group style={{ width: '30%', height : '20px' }}>
          <Label>Temporarily Disable Account Access  </Label>
        </Input.Group>
        <Switch checked={disabled}  onChange={(checked) => onChange(checked, id)}/>
      </Row>
      <Row align='middle'>
        <Input.Group style={{ width: '80%' }}>
          <Label>API Key</Label>
          <StyledInput
            disabled
            value={apiKey || ''}
            addonAfter={apiAddon}
            placeholder='API Key'
          />
        </Input.Group>
        {apiKey && (
          <CopyToClipboard
            text={apiKey}
            successMessage='Copied API Key to clipboard'
          />
        )}
      </Row>
      <Row align='middle'>
        <Input.Group style={{ width: '80%' }}>
          <Label>Secret</Label>
          <StyledInput
            disabled
            value={requestSecret || ''}
            placeholder='Secret'
            addonAfter={secretAddon}
          />
        </Input.Group>
        {requestSecret && (
          <CopyToClipboard
            text={requestSecret}
            successMessage='Copied Secret to clipboard'
          />
        )}
      </Row>
    </>
  )
}
export default AccessKeys

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Aggregator } from '../schema/aggregatorAction'

type AggregatorState = {
  aggregators: Aggregator[]
  totalAggregator: number
  loading: boolean
  error: null | string
}

type AggregatorPayload = {
  result: Aggregator[],
  totalCount: number
}

let initialState: AggregatorState = {
  aggregators: [],
  totalAggregator: 0,
  loading: false,
  error: null
}

function startLoading(state: AggregatorState) {
  state.loading = true
  state.error = null
}

function loadingFailed(state: AggregatorState, action: PayloadAction<string>) {
  state.loading = false
  state.error = action.payload
}

const aggregatorSlice = createSlice({
  name: 'aggregator',
  initialState,
  reducers: {
    fetchAggregatorsRequest: startLoading,
    fetchAggregatorsFailure: loadingFailed,
    fetchAggregatorsSuccess(state, action: PayloadAction<AggregatorPayload>) {
      state.loading = false
      state.error = null
      state.aggregators = action.payload.result
      state.totalAggregator = action.payload.totalCount
    },
    updateAggregatorRequest: startLoading,
    updateAggregatorFailure: loadingFailed,
    updateAggregatorSuccess(state) {
      state.loading = false
      state.error = null
    }
  }
})


export const {
  fetchAggregatorsRequest,
  fetchAggregatorsSuccess,
  fetchAggregatorsFailure,
  updateAggregatorRequest,
  updateAggregatorSuccess,
  updateAggregatorFailure
} = aggregatorSlice.actions

export const aggregatorReducer = aggregatorSlice.reducer

import React, { useState } from 'react'

import ViewContainer from 'common/components/ViewContainer'
import TransactionsTable from 'core/TransactionsTable'
import { Role } from 'common/store/auth/slice'
import { URL_ACCOUNTS } from 'common/api/paths'
import { Button } from 'antd'
import PhazeRedeemTab from '../PhazeRedeemTab'
import { useSelector } from 'react-redux'
import { RootState } from 'common/store'

const SATransactions: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState(1)
  const role = useSelector((state: RootState) => state.auth.role)
  const url = `${URL_ACCOUNTS}/all/transactions`

  return (
    <ViewContainer title='Transactions'>
      <div
        style={{
          display: 'flex',
          gap: 5,
        }}
      >
        <Button onClick={() => setSelectedTab(1)}>Phaze Redeem Details</Button>
        <Button onClick={() => setSelectedTab(0)}>Transactions</Button>
      </div>
      {selectedTab === 0 ? (
        <TransactionsTable
          role={role}
          fetchUrl={url}
          allTransaction
          displayContainer
        />
      ) : selectedTab === 1 ? (
        <PhazeRedeemTab />
      ) : null}
    </ViewContainer>
  )
}

export default SATransactions

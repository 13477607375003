import React from 'react'
import { logout, getUserInfo } from 'common/store/auth/actions'
import { useDispatch, useSelector } from 'react-redux'
import Header from './Header'
import { RootState } from 'common/store/rootReducer'
import { useNavigate } from 'react-router-dom'

const PageContainer: React.FC = () => {
  const dispatch = useDispatch()
  const email = useSelector((state: RootState) => state.auth.email)
  const navigate = useNavigate()

  const handleLogout = () => dispatch(logout(navigate))
  const handleGetUserInfo = () => dispatch(getUserInfo())

  return (
    <Header
      email={email}
      logout={handleLogout}
      getUserInfo={handleGetUserInfo}
    />
  )
}

export default PageContainer

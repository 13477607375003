import React from 'react';
import * as Icons from '@ant-design/icons';

interface IconBaseProps {
  type: string;
  className?: string;
  onClick?: () => void;
}

export const IconBase: React.FC<IconBaseProps> = ({ type, className, onClick }) => {
  const IconComponent = (Icons as any)[type];
  return IconComponent ? <IconComponent className={className} onClick={onClick}/> : null;
};
import React, { useEffect, useState } from 'react'
import Cards from './Cards';
import { useNavigate } from "react-router-dom";
import { fetchBrands } from './fetchRedeem';


const Redeem = () => {

  const [brands,setBrands] = useState<Array<any>>([]);
  const [isLoading,setIsLoading] = useState(false);
  let history = useNavigate();

  useEffect(() => {
    try{
      const fetchData = async () =>  {
        // You can await here
        const brands = await fetchBrands()
        console.log(brands)
        setBrands(brands)
        setIsLoading(false)
        // ...
      }
      setIsLoading(true)
      fetchData()
    }catch(e){
      setIsLoading(false)
    }
  }, [])

  const clickHandler = (productId: number) =>{
    console.log(productId)
    history(`/redeem/brand-details/${productId}`)
  }

  return (
    <Cards data={brands} isLoading={isLoading} clickHandler={clickHandler} />
  )
}

export default Redeem

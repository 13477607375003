import { Button, Pagination, Table } from 'antd'
import { ColumnProps, TableProps } from 'antd/lib/table'
import { ContentContainer } from 'common/components/ContentContainer'
import moment from 'moment'
import React, { useState } from 'react'
import HistoryDetailsModal from './HistoryDetailsModal'
import useBroadcastHistories, {
  BroadcastHistory as BroadcastHistoryType
} from './hooks/useBroadcastHistories'

const BroadcastHistory = () => {
  const [selectedItemId, setSelectedItemId] = useState<number>()
  const {
    data,
    loading,
    page,
    limit,
    setLimit,
    setPage
  } = useBroadcastHistories()

  const handleChange: TableProps<BroadcastHistoryType>['onChange'] = (
    pagination,
    filters,
    sorter,
    extra
  ) => {}

  const onPageChange = (page: number, pageSize?: number) => {
    if (pageSize) setLimit(pageSize)
    setPage(page)
  }

  const openModal = (id: number) => {
    setSelectedItemId(id)
  }

  const closeModal = () => {
    setSelectedItemId(undefined)
  }

  const selectedItem = data
    ? data?.histories.find(item => item.id === selectedItemId)
    : undefined

  const columns: ColumnProps<BroadcastHistoryType>[] = [
    {
      key: 'id',
      title: 'ID',
      dataIndex: 'id'
    },
    {
      key: 'templateName',
      title: 'Template Name',
      dataIndex: 'templateName'
    },
    {
      key: 'subject',
      title: 'Subject',
      dataIndex: 'subject'
    },
    {
      key: 'sentToEmails',
      title: 'Sent To Emails',
      dataIndex: 'sentToEmails',
      render(text, record) {
        return (
          record.sentToEmails
            .split(',')
            .slice(0, 1)
            .join(', ') +
          // if there is more, then add '{number} more'
          (record.sentToEmails.split(',').length > 1
            ? ` + ${record.sentToEmails.split(',').length - 1} more`
            : '')
        )
      }
    },
    {
      key: 'created_at',
      title: 'Sent At',
      dataIndex: 'created_at',
      render(text, record) {
        return moment(record.created_at).isSame(moment(), 'day')
          ? moment(record.created_at).fromNow()
          : moment(record.created_at).format('DD MMM YYYY, hh:mm A')
      }
    },
    {
      key: 'actions',
      title: 'Actions',
      dataIndex: 'actions',
      render(text, record) {
        return <Button onClick={() => openModal(record.id)}>View</Button>
      }
    }
  ]

  return (
    <ContentContainer>
      <h2>Broadcast History</h2>
      <Table
        columns={columns}
        dataSource={data?.histories}
        loading={loading}
        pagination={false}
        rowKey={'id'}
        onChange={handleChange}
        size='small'
      />
      <Pagination
        style={{ marginTop: 10 }}
        pageSize={limit}
        current={page}
        defaultCurrent={1}
        total={data?.count || 0}
        onChange={onPageChange}
      />
      <HistoryDetailsModal
        selectedItem={selectedItemId}
        closeModal={closeModal}
        data={selectedItem}
      />
    </ContentContainer>
  )
}

export default BroadcastHistory

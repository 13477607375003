import { fetchUsers, deleteTeamMember } from 'common/store/team/actions'
import { authSelectors } from 'common/store/auth/slice'
import { connect } from 'react-redux'

import Team from './Team'
import { RootState } from 'common/store/rootReducer'
const mapDispatchToProps = {
  fetchUsers,
  deleteUser: deleteTeamMember
}

const mapStateToProps = (state: RootState) => {
  return {
    users: state.team.users,
    totalTeamMember: state.team.totalUsers,
    activeUser: authSelectors.getActiveUsername(state)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Team)

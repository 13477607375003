import styled from 'styled-components'
import { Button } from 'antd'

export const ActionsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
`

export const Actions = styled.div`
  a {
    color: ${({ theme }) => theme.colours.primary};
  }

  > span {
    margin: 0 5px;
  }
`

export const ActionButton = styled(Button)`
  padding: 0;
  color: ${({ theme }) => theme.colours.primary};
`

import React from 'react'
import { notification } from 'antd'
import { StyledAlert } from './styledComponents'

type Type = 'success' | 'info' | 'warning' | 'error'

export const openNotification = (message: string, type: Type) => {
  notification.open({
    message: <StyledAlert message={message} type={type} showIcon closable />,
    description: null,
    style: {
      visibility: 'hidden',
      padding: 0,
      width: 400,
      right: 20,
      borderRadius: 0
    }
  })
}

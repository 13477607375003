const getCurrencyToLocale = (number: number, currency: string) => {

  try {
    return `${currency} ${number?.toLocaleString(undefined,{minimumFractionDigits: 2,maximumFractionDigits: 2})}`

    // return number.toLocaleString(undefined, {
    //   style: 'currency',
    //   currency: currency,
    //   currencyDisplay: 'code'
    // })
  } catch (e) {
    return `${currency} ${number?.toLocaleString()}`
  }
}

export default getCurrencyToLocale

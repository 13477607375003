import styled, { css } from 'styled-components'
import { Select as SelectBase } from 'antd'

export const Select = styled(SelectBase)<{ fullWidth?: boolean }>`
  && .ant-select-selection {
    border-radius: 25px;
  }
  ${props =>
    props.fullWidth &&
    css`
      width: 100%;
    `}
`

import styled from 'styled-components'

export const TableContainer = styled.div`
  && .ant-table-column-title {
    font-weight: 600;
  }

  && a {
    color: ${({ theme }) => theme.colours.primary};
  }
`

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;

  .ant-pagination-item,
  .ant-pagination-item-link {
    border-radius: 20px;
  }

  .ant-pagination-item-active {
    border-color: ${({ theme }) => theme.colours.primary};
  }
`

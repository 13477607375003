import styled from 'styled-components'
import { Form } from 'antd'

export const FormItem = styled(Form.Item)`
  margin-bottom: 10px;
  && {
    .ant-form-item-label {
      line-height: initial;
    }

    label:after {
      display: none;
    }

    label:not(.ant-checkbox-wrapper) {
      margin-left: 12px;
    }
  }
`

import styled from 'styled-components'
import { Row } from 'antd'

export const FormRow = styled(Row)`
  background: ${({ theme }) => theme.colours.bgLight};
  border-radius: 5px;
  box-shadow: 0 20px 30px 0 rgba(0, 0, 0, 0.15);
  padding: 30px;

  form {
    max-width: 500px;
  }
`
export const FormTitle = styled.h2`
  font-size: 16px;
  font-weight: 600;
  color: ${({ theme }) => theme.colours.formTitle};
`

import styled from 'styled-components'

export const Wrapper = styled.div`
  padding: 35px 120px 35px 56px;
`
export const ViewHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
  margin-bottom: 25px;
`

export const PageTitle = styled.h1`
  font-size: ${({ theme }) => theme.font.lg};
  font-weight: bold;
  margin-bottom: 0;
`

export const SubPath = styled.span`
  text-transform: capitalize;
`
export const ActivePath = styled.span`
  color: ${({ theme }) => theme.colours.text};
  text-transform: capitalize;
`

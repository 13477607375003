import styled from 'styled-components'
import { Empty as EmptyBase } from 'antd'

export const Empty = styled(EmptyBase)`
  padding-top: 10px;
  .ant-empty-image {
    margin-bottom: 40px;
  }

  && a {
    margin-bottom: 15px;
  }
`

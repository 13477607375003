import React from 'react'
import { Route, Routes } from 'react-router-dom'

import { RoutePaths } from 'core/Router/routePaths'
import Container from 'common/components/Container'
import SignIn from 'core/LoginContainer/SignIn'
import {
  LoginLeftPane,
  LoginRightPane,
  Row,
  Logo,
  LoginLeftContainer
} from './styledComponents'

import PhazeLogo from 'assets/logo-positive.svg'
import ChangeTemporaryPassword from './ChangeTemporaryPassword'
import ForgotPassword from './ForgotPassword'

const LoginContainer: React.FC = () => {
  return (
    <Container>
      <Row>
        <LoginLeftPane>
          <LoginLeftContainer>
          <Routes>
          <Route path="/" element={<SignIn />} />
          <Route path={RoutePaths.RESET_PASSWORD} element={<ForgotPassword />} />
          <Route path={RoutePaths.CHANGE_TEMPORARY_PASSWORD} element={<ChangeTemporaryPassword />} />
        </Routes>
            {/* <PasswordDescription>
              If you want to reset your password,{' '}
              <Link to='/reset-password'>click here</Link>.
            </PasswordDescription> */}
          </LoginLeftContainer>
        </LoginLeftPane>
        <LoginRightPane>
          <Logo src={PhazeLogo} alt='company logo' />
        </LoginRightPane>
      </Row>
    </Container>
  )
}

export default LoginContainer

import React, { useEffect, useState } from 'react'

import { Button } from 'common/components/Button'
import ViewContainer from 'common/components/ViewContainer'
import { ContentContainer } from 'common/components/ContentContainer'
import {
    TodaySection,
} from '../Dashboard/styledComponents'
import { Input, Table } from 'antd'
import { BrandCountries } from 'common/store/schema/app'
import { Field, FieldProps, Form, Formik, FormikHelpers } from 'formik'
import FieldSelect from 'common/components/FieldSelect'
import validationsSchema, { FormValues } from './validationsSchema'
import { openNotification } from 'common/components/openNotification'
import { client } from 'common/api/client'
import { URL_BRAND_LIST_PER_COUNTRY } from 'common/api/paths'
import PersistentListDetailsModals from './PersistentListDescription'
import { CardLogo } from 'core/Redeem/Cards/styledComponents'
import { useNavigate } from 'react-router-dom'
import { RoutePaths } from 'core/Router/routePaths'
import { SaveOutlined } from '@ant-design/icons'


type BrandListBasedOnTypeProps = {
    brandCountryList: Array<BrandCountries>,
    brandListType: string,
    getError?: () => string
    addProduct: (updatedValue: any, brandListType: string, currentPage?: number, perPage?: number, searchParams?: string) => void
}

const AddPersistentList: React.FC<BrandListBasedOnTypeProps> = ({
    brandCountryList,
    brandListType,
    addProduct,
    getError
}) => {
    const enableReinitialize = true
    const [productList, setProductList] = useState([])
    const [selectedProductList, setSelectedProductList] = useState([])
    const [countryName, setCountry] = useState('')
    const [searchParams, setSearchParams] = useState('')
    const [inputText, setInputText] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [clientFacingId, setClientFacingId] = useState()
    const [productId, setProductId] = useState()
    const [refreshCatalog, setRefreshCatalog] = useState(false);

    let history = useNavigate();
    let maxNumber = 2
    if (brandListType === 'exclusion') {
        maxNumber = 1
    }

    const onSelectChange = (newSelectedRowKeys: any, selectedRows: any) => {
        if (selectedRowKeys.length <= maxNumber) {
            setSelectedRowKeys(newSelectedRowKeys);
            setSelectedProductList(selectedRows)
        } else {
            openNotification(`You can only select ${maxNumber} products`, 'error')
        }
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };
    const hasSelected = selectedRowKeys.length >= 2;

    useEffect(() => {
        if (selectedRowKeys.length > 0) {
            if (selectedRowKeys && selectedRowKeys.length > maxNumber) {
                setSelectedRowKeys(prev => prev.slice(0, -1))
                setSelectedProductList(prev => prev.slice(0, -1))
                openNotification(`You only select ${maxNumber} products`, 'error')
            }
            if (brandListType === 'mapped') {
                if (selectedRowKeys.length === 1) {
                    setClientFacingId(selectedRowKeys[0])
                }
                if (selectedRowKeys.length === 2) {
                    setProductId(selectedRowKeys[1])
                }
            } else {
                setProductId(selectedRowKeys[0])
            }

        }
    }, [selectedRowKeys])

    useEffect(() => {
        const fetchProducts = async (setValue: React.Dispatch<React.SetStateAction<never[]>>, countryValue: string) => {
            setIsLoading(true)
            try {
                const productListData: any = await client.get(`${URL_BRAND_LIST_PER_COUNTRY(countryValue)}?type=ex_or_map&searchParams=${searchParams}`, { type: 'ex_or_map', searchParams })
                setValue(productListData.data.brands)
            } catch (err: any) {
                openNotification(err.response.data.error, 'error')
                setValue([])
            }
            setIsLoading(false)
            setRefreshCatalog(false)
        }

        if (countryName || refreshCatalog) {
            fetchProducts(setProductList, countryName)
        }

    }, [countryName, searchParams, refreshCatalog])
    //Fetch the products
    const getClientFacingProducts = async (country: any) => {
        //remove search params each time we change country.
        setInputText('')
        setSearchParams('')
        setCountry(country)
    }

    const initialValues = {
        country: '',
    }

    let title = brandListType === 'mapped' ? 'Add to Persistent List' : 'Add to Exclusion List'

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id'
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: 'Discount',
            dataIndex: 'discount',
            key: 'discount'
        },
        {
            title: 'Image',
            key: 'image',
            align: 'center' as 'center',
            render: (text: any, record: any) => {
                return (
                    <CardLogo>
                        <img src={text.image} alt={text.brandName} />
                    </CardLogo>)
            }
        },
        {
            title: 'Details',
            key: 'details',
            align: 'center' as 'center',
            render: (text: any, record: any) => {
                return (
                    <PersistentListDetailsModals description={text.description} howToUse={text.howToUse} terms={text.terms} />
                )
            }
        },
    ]

    //These are columns used once user selects products. 
    const selectedColumns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id'
        },
        {
            title: 'name',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: 'Discount',
            dataIndex: 'discount',
            key: 'discount'
        },
        {
            title: 'Details',
            key: 'details',
            align: 'center' as 'center',
            render: (text: any, record: any) => {
                return (
                    <PersistentListDetailsModals description={text.description} howToUse={text.howToUse} terms={text.terms} />
                )
            }
        },
        {
            title: 'Actions',
            key: 'actions',
            align: 'center' as 'center',
            render: (text: any, record: any) => {
                if (text.id === clientFacingId) {
                    return <h1 style={{ marginLeft: 0 }}>Client Facing Id</h1>
                } else {
                    return (
                        <>
                            <h1 style={{ marginLeft: 0 }}>Product Id</h1>
                            {brandListType === 'mapped' && (
                                <Button onClick={() => {
                                    setProductId(clientFacingId)
                                    setClientFacingId(text.id)
                                }}>Make It Client Facing</Button>
                            )}
                        </>
                    )
                }
            }
        },
    ]
    const uniqueBrandCountryList = brandCountryList.filter((ctylist, index, self) =>
        index === self.findIndex((t) => (
            t.countryName === ctylist.countryName
        ))
    )


    const onSubmit = async (values: FormValues, { resetForm }: FormikHelpers<FormValues>) => {
        // const updatedValues = {
        //   productId : values.productId,
        //   clientFacingProductId : values.clientFacingProductId
        // }

        // addProductToTheList(updatedValues)

        try {
            // await client.post(URL_ADD_EXCLUSION_MAPPED, updatedValues)
            resetForm();
        } catch (err: any) {
            openNotification(err.response.data.error, 'error')
        }
    }

    //Generate key value for select options
    const getCountryOptions = uniqueBrandCountryList.map((cty: BrandCountries) => ({
        key: cty.countryName,
        value: cty.countryName
    }))

    return (
        <>
            {selectedProductList.length >= maxNumber && (
                <>
                    <ViewContainer title="Selected Products">
                        <Table
                            // rowSelection={rowSelection}
                            columns={selectedColumns}
                            dataSource={selectedProductList}
                            loading={isLoading}
                            pagination={false}
                            rowKey="id"
                            // onChange={handleChange}
                            size='small'
                        />
                        <div style={{ marginBottom: 16 }}>
                            <Button type="primary" onClick={async () => {
                                if (brandListType === 'mapped') {
                                    await addProduct({
                                        productId: `${productId}`,
                                        clientFacingProductId: `${clientFacingId}`
                                    }, "mapped")

                                    setSearchParams('')
                                } else {
                                    await addProduct({ productId: `${productId}` }, "exclusion")
                                }
                                setRefreshCatalog(true)
                                setClientFacingId(undefined)
                                setProductId(undefined)
                                setSelectedRowKeys([])
                                setSelectedProductList([])

                            }}>
                                {brandListType === 'mapped' ? 'Add To Persistent List' : 'Add To Exclusion list'}
                            </Button>
                        </div>
                    </ViewContainer>
                </>
            )}
            <ViewContainer title={title}>

                <Formik
                    initialValues={initialValues}
                    validationSchema={validationsSchema}
                    onSubmit={onSubmit}
                    enableReinitialize={enableReinitialize}
                >
                    {({ handleSubmit, errors, touched }) => (
                        <Form onSubmit={handleSubmit} id='addProductForm'>
                            <Field name='country' as='select'>
                                {(fieldProps: FieldProps) => {
                                    return (
                                        <FieldSelect
                                            label='Country List'
                                            placeholder='Please select'
                                            options={getCountryOptions}
                                            getValue={getClientFacingProducts}
                                            required
                                            {...fieldProps}
                                        />
                                    )
                                }}
                            </Field>
                        </Form>
                    )}
                </Formik>
                {countryName && (
                    <>
                        <Input
                            style={{ marginTop: 2 }}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    setSearchParams(inputText)
                                }
                            }}
                            onChange={e => setInputText(e.target.value)}
                            value={inputText}
                            placeholder='Search'
                            disabled={isLoading}
                        />
                        <Button
                            style={{ marginTop: 4 }}
                            onClick={() => {
                                setSearchParams(inputText)
                            }}
                        >
                            <SaveOutlined /> Search
                        </Button>
                    </>
                )}
                {productList.length > 0 && (
                    <>
                        <TodaySection>
                            <ContentContainer>
                                <Table
                                    rowSelection={rowSelection}
                                    columns={columns}
                                    dataSource={productList}
                                    loading={isLoading}
                                    pagination={false}
                                    rowKey="id"
                                    // onChange={handleChange}
                                    size='small'
                                />
                            </ContentContainer>
                        </TodaySection>
                    </>
                )}
            </ViewContainer>
        </>
    )
}

export default AddPersistentList

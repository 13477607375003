import styled from 'styled-components'
import { Layout } from 'antd'

export const Container = styled.div`
  height: 100vh;
`
export const MainSection = styled(Layout)`
  box-shadow: inset 20px 0 36px -20px rgba(0, 0, 0, 0.15);
  height: 100vh;
  background: #fff;
`

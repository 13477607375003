import * as yup from 'yup'
import { matchPostalCodeUS, matchPostalCodeCA } from './regex'

export const postalCodeSchema = yup
  .string()
  .when('country', (country: string, schema: any) => {
    if (country === 'United States' || country === 'US') {
      return schema
        .required()
        .matches(matchPostalCodeUS, 'Must be of format 33333-3333 or 33333')
    }

    if (country === 'Canada' || country === 'CA') {
      return schema
        .required()
        .matches(
          matchPostalCodeCA,
          'Must be of format A1A1A1, A1A 1A1 or A1A-1A1'
        )
    }

    return schema.notRequired()
  })
  .label('Postal Code')

export const provinceSchema = yup
  .string()
  .when('country', {
    is: value => ['CA', 'US', 'Canada', 'United States'].includes(value),
    then: yup.string().required(),
    otherwise: yup.string().notRequired()
  })
  .label('Province')

export const passwordSchema = yup
  .string()
  .min(8)
  .matches(/[a-z]+/, 'Must contain a lower case letter')
  .matches(/[A-Z]+/, 'Must contain an upper case letter')
  .matches(/[0-9]+/, 'Must contain a number')

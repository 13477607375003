import * as yup from 'yup'

const superAdminValidationRegex = /[a-z.]*[@]\b(?:phaze.io|coinchange.io)/

export default yup.object().shape({
  firstName: yup
    .string()
    .required()
    .label('First Name'),
  lastName: yup
    .string()
    .required()
    .label('Last Name'),
  email: yup
    .string()
    .email()
    .required()
    .when('role', (role : any, schema : any) => { 
        if(role === 'super_admin'){
          return schema.matches(superAdminValidationRegex,'Invalid email for the provided role.')
        }
    })    
    .label('E-mail'),
  role: yup
    .string()
    .required()
    .when('email', {
      is: (email) => email && email.match(superAdminValidationRegex),
      then: yup.string().required(),
      otherwise: yup.string().required().matches(/partner_admin|partner_user/,'Email can not assigned for the selected access level'),
    })   
    .label('Access Level'),
  accountId: yup
    .number()
    .nullable()
    .notRequired()
    .label('Account')
},[['role','email']])

import jwt from 'jwt-decode'

interface JWTPayload {
  exp: number
}

const getCurrentUnixTime = () => Math.round(new Date().getTime() / 1000)

export const decodeJWT = (token: string) => {
  const decoded = jwt(token)
  if (!decoded) return undefined
  return decoded as JWTPayload
}

export const getToken = () => {
  return window.localStorage.getItem('token') || ''
}

export const getTokenExpiration = (token: string) => {
  if (!token) return 0
  const decoded = decodeJWT(token)
  if (!decoded) return 0
  return decoded.exp
}

export const validateToken = (token: string) => {
  if (getCurrentUnixTime() > getTokenExpiration(token)) return ''
  return token
}

export const castToNumber = (value: string | number | undefined) => {
  if (!value) return 0
  if (typeof value === 'string') return parseFloat(value)
  return value
}

export const castToPercentage = (value: string | number | undefined) => {
  const number = castToNumber(value)
  if (!number) return 0
  return number / 100
}

export const sortByName = (a: { name: string }, b: { name: string }) => {
  const nameA = a.name.toUpperCase()
  const nameB = b.name.toUpperCase()
  if (nameA < nameB) {
    return -1
  }
  if (nameA > nameB) {
    return 1
  }

  return 0
}

import * as yup from 'yup'
import { passwordSchema } from 'common/utils/validationSchemas'

export default yup.object().shape({
  email: yup
    .string()
    .email()
    .required(),
  oldPassword: yup.string().required(),
  newPassword: passwordSchema
})

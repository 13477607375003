import axios from 'axios'
import { authSelectors } from 'common/store/auth/slice'
import { store } from 'common/store'

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE
})

instance.interceptors.request.use(config => {
  const token = authSelectors.getToken(store.getState())
  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }
  return config
})

export const client = {
  get: <T>(url: string, options = {}) => instance.get<T>(url, options),
  post: <T>(url: string, payload: object) => instance.post<T>(url, payload),
  put: <T>(url: string, payload: object) => instance.put<T>(url, payload),
  delete: <T>(url: string) => instance.delete<T>(url)
}

import { resetPassword, confirmPassword } from 'common/store/auth/actions'
import { connect } from 'react-redux'
import ForgotPassword from './ForgotPassword'
import { RootState } from 'common/store/rootReducer'
const mapDispatchToProps = {
  resetPassword,
  confirmPassword
}

const mapStateToProps = (state: RootState) => ({
  error: state.auth.error,
  needsPasswordConfirm: state.auth.needsPasswordConfirm
})

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword)

import React from 'react'

import { Row, Col } from 'antd'

import ViewContainer from 'common/components/ViewContainer'
import TeamMemberForm from '../TeamMemberForm'

interface AddTeamMemberProps {
  onSubmit: (values: object) => void
}

const AddTeamMember: React.FC<AddTeamMemberProps> = ({ onSubmit }) => {
  return (
    <ViewContainer title={'Add New Team Member'} subPath='Team'>
      <Row justify='space-between'>
        <Col span={16}>
          <TeamMemberForm onSubmit={onSubmit} />
        </Col>
      </Row>
    </ViewContainer>
  )
}

export default AddTeamMember

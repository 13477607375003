import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'

import { editTeamMember, fetchUsers } from 'common/store/team/actions'
import { RootState } from 'common/store/rootReducer'
import { teamSelectors } from 'common/store/team/slice'

import EditTeamMember from './EditTeamMember'

interface RouteParams {
  userId: string
}

const EditTeamMemberContainer: React.FC = () => {
  const { userId } = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const user = useSelector((state: RootState) => 
    teamSelectors.getUserById(state, parseInt(userId!))
  )

  const handleFetchUsers = (currentPage: number, perPage: number,sortBy: string,order: string,
    filterByPartner: string | null,filterByName: string | null, filterByRole?: string | null) => {
    dispatch(fetchUsers(currentPage, perPage, sortBy, order, filterByPartner, filterByName, filterByRole))
  }
  const handleSubmit = (userId: number, values: object) => {
    dispatch(editTeamMember(userId, values, navigate))
  }

  return (
    <EditTeamMember 
      user={user} 
      fetchUsers={handleFetchUsers} 
      onSubmit={handleSubmit} 
    />
  )
}

export default EditTeamMemberContainer

export interface FinanceDetails {
  balance: number
  transactions: number
  transactionsSucceeded: number
  transactionsFailed: number
  averageTransaction: number
  totalTransactions: number
  salesInSelectedRange: number
  totalSales: number
  selectedRange: number
  averageEarnedPercentage: number
  displayCurrency: string
  totaltransactionsFailed: number
  totaltransactionsSucceeded: number
  holdingBalance: number
  totalRevenueInRange: number
  totalRevenue: number
  transactionsCancelled: number
  totalTransactionsCancelled: number
}

export interface SuperAdminFinanceDetails extends FinanceDetails {
  totalCommisionsInRange: number
  totalDeltaDiscountProfitInRange: number
  totalDeltaDiscountProfit: number
  totalCommisions: number
  totalFxInRange: number
  totalFx: number
  totalMarginOffsetInRange: number
  totalMarginOffset: number
  totalBrakageSplitInRange: number
  totalBreakageSplit: number
  totalTransactionsCancelled: number
  transactionsCancelled: number
}

export const isSuperAdminFinanceDetails = (
  financeDetails: FinanceDetails | SuperAdminFinanceDetails
): financeDetails is SuperAdminFinanceDetails => {
  return (
    (financeDetails as SuperAdminFinanceDetails).totalCommisionsInRange !==
    undefined
  )
}

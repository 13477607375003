import { useEffect, useState } from 'react'
import { client } from 'common/api/client'
import fileDownload from 'js-file-download'
import { DateTime } from 'luxon'

type Args = {
  perPage: number
  currentPage: number
  orderBy: {
    field: string
    sort: string
  }
  searchItems?: {
    productId?: string
    supplierFacingProductId?: string
    orderId?: string
    organizationName?: string
    productName?: string
    securityKey?: string
    status?: string
    state?: string
    attempt?: null | number
    attemptWithAggregator?: null | number
    ipAddress?: null | number
  }
  fromDate: string
  toDate: string
  expiryDateFrom?: string
  expiryDateTo?: string
}

export type PhazeRedeemTransactionsResponse = {
  result: PhazeRedeemTransaction[]
  totalPages: number
  totalCount: number
  currentPage: number
}

type TransactionDetail = {
  productDiscountPercent: number
  productPrice: number
  partnerCommission: number
  phazeCommission: number
  productPriceInAccountCurrency: number
  costForTransaction: number
  transactionFeeInAccountCurrency: number
}

export const TransactionBlockedReasons: {[key: number]: string} =  {
  0: 'Not Blocked',
  1: 'Blacklisted IP',
  2:  'Sanctioned Country',
  3: 'VPN or Proxy or TOR'
}

export type PhazeRedeemTransaction = {
  id: number
  organizationName: string
  productPrice: string
  productId: string
  productName: string
  supplierFacingProductId: string
  orderId: string
  status: string
  state: string
  attempt: number
  phazeRedeem: boolean
  attemptWithAggregator: number
  errors: string[]
  successData?: string

  // if the user purchased through purchaseByEndUser.ts
  finalProductId: string | undefined
  finalProductName: string | undefined

  transactionDetails?: {
    before: TransactionDetail
    after: TransactionDetail | null
  } | null

  redeemDetails?: {
    url: string
    code: string
    validityDate: Date
    voucherCurrency: string
    faceValue: number
    redemptionLocation: {
      blockedReason: number, ipAddress: string, isVPN: boolean, isProxy: boolean, country: string, isTOR: boolean
}[]
  }

  expiryDate: Date
  created_at: Date
}

const useDownloadPhazeRedeemCSV = (dateTo: string, dateFrom: string) => {
  const download = () => {
    client
      .get<PhazeRedeemTransactionsResponse>(`/phaze-redeem-transactions.csv`, {
        params: {
          dateTo,
          dateFrom
        }
      })
      .then(r => {
        fileDownload(r.data as any, 'phaze-redeem-transactions.csv')
      })
  }

  return {
    download
  }
}

const useFetchPhazeRedeemTransactions = ({
  perPage,
  currentPage,
  orderBy,
  searchItems,
  fromDate,
  toDate,
  expiryDateFrom,
  expiryDateTo
}: Args) => {
  const [data, setData] = useState<PhazeRedeemTransactionsResponse>({result: 
    [],
    totalPages: 0,
    totalCount: 0,
    currentPage: 0,})
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<any>(null)

  const params = {
    perPage,
    currentPage,
    order: orderBy.field,
    sortBy: orderBy.sort,
    fromDate,
    toDate,
    productId: searchItems?.productId,
    supplierFacingProductId: searchItems?.supplierFacingProductId,
    orderId: searchItems?.orderId,
    organizationName: searchItems?.organizationName,
    productName: searchItems?.productName,
    attemptWithAggregator: searchItems?.attemptWithAggregator,
    attempt: searchItems?.attempt,
    securityKey: searchItems?.securityKey
      ? decodeURIComponent(searchItems?.securityKey)
      : undefined,
    status : searchItems?.status,
    state: searchItems?.state,
    expiryDateFrom: expiryDateFrom
      ? DateTime.fromISO(expiryDateFrom)
          .endOf('day')
          .toFormat('yyyy-MM-dd')
      : undefined,
    expiryDateTo: expiryDateTo
      ? DateTime.fromISO(expiryDateTo)
          // add 1 day to include the last day too
          .plus({ days: 1 })
          .endOf('day')
          .toFormat('yyyy-MM-dd')
      : undefined,
    ipAddress: searchItems?.ipAddress
  }

  const fetchData = () => {
    setLoading(true)

    client
      .get<PhazeRedeemTransactionsResponse>(`/phaze-redeem-transactions`, {
        params
      })
      .then(r => {
        setData(r.data)
      })
      .catch(e => {
        setError(e.response.data)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(
    () => {
      fetchData()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      perPage,
      currentPage,
      orderBy,
      fromDate,
      toDate,
      searchItems,
      expiryDateFrom,
      expiryDateTo
    ]
  )

  const { download } = useDownloadPhazeRedeemCSV(toDate, fromDate)

  return {
    data,
    loading,
    setData,
    error,
    refetch: fetchData,
    downloadCSV: download
  }
}

export default useFetchPhazeRedeemTransactions

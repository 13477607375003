import React, { FC, ReactNode, useEffect, useState } from 'react'
import { Select } from 'antd'

const { Option } = Select

interface IMultiSelectProps {
  emails: {
    organization_name: string
    support_email: string
  }[]
  value: string[]
  handleChange: (value: string[]) => void
  loading: boolean
}

const MultiSelect: FC<IMultiSelectProps> = ({
  emails,
  handleChange,
  value,
  loading
}) => {
  const [list, setList] = useState<ReactNode[]>([])

  useEffect(() => {
    setList(
      emails.map(e => (
        <Option key={e.support_email}>{e.organization_name}</Option>
      ))
    )
  }, [emails])

  return (
    <Select
      mode='tags'
      style={{ width: '100%' }}
      placeholder='Please select users'
      defaultValue={[]}
      onChange={handleChange}
      value={value}
      loading={loading}
    >
      {list}
    </Select>
  )
}

export default MultiSelect

import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit'
import { AppData, Countries } from '../schema/app'
import { RootState } from '../rootReducer'
import { sortByName } from 'common/utils/common'

type AppState = {
  loading: boolean
  error: string | null
  data: AppData
}

const initialState: AppState = {
  loading: false,
  error: null,
  data: {
    cryptoSources: [],
    fiatSources: [],
    currencyCountries: {},
    countries: {},
    brandCountriesListWithCode : []
  }
}

function startLoading(state: AppState) {
  state.loading = true
  state.error = null
}

function loadingFailed(state: AppState, action: PayloadAction<string>) {
  state.loading = false
  state.error = action.payload
}

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    fetchApplicationDataRequest: startLoading,
    fetchApplicationDataFailure: loadingFailed,
    fetchApplicationDataSuccess(state, action: PayloadAction<AppData>) {
      state.loading = false
      state.error = null
      state.data = action.payload
    }
  }
})

const getAppData = (state: RootState) => state.app.data

export const appSelectors = {
  getCurrencies: createSelector([getAppData], data => {
    const currencyArray = Object.values(data.currencyCountries)
    return Array.from(new Set(currencyArray)).sort()
  }),
  getCountries: createSelector([getAppData], data => {
    return Object.values(data.countries as Countries).sort(sortByName)
  }),
  getBrandCountries: createSelector([getAppData], data => {
    return data.brandCountriesListWithCode
  })
}

export const {
  fetchApplicationDataRequest,
  fetchApplicationDataSuccess,
  fetchApplicationDataFailure
} = appSlice.actions

export const appReducer = appSlice.reducer

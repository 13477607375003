import { Dispatch } from '@reduxjs/toolkit'
import { openNotification } from 'common/components/openNotification'
import {
  fetchUsersRequest,
  fetchUsersSuccess,
  fetchUsersFailure,
  createTeamMemberRequest,
  createTeamMemberSuccess,
  createTeamMemberFailure,
  editTeamMemberRequest,
  editTeamMemberSuccess,
  editTeamMemberFailure,
  deleteTeamMemberRequest,
  deleteTeamMemberSuccess,
  deleteTeamMemberFailure
} from './slice'
import { client } from 'common/api/client'
import { URL_USERS } from 'common/api/paths'
import { RoutePaths } from 'core/Router/routePaths'
import { store } from '..'
import { User } from '../schema/team'
import { NavigateFunction } from 'react-router-dom'

type FetchUsersResponse = {
  result: User[],
  totalCount: number
}

export const fetchUsers = (currentPage: number, perPage: number,
                                        sortBy: string, order: string,
                          filterByPartner: string | null,filterByName: string | null,teamName?: string | null,filterByRole?: string | null) => async (dispatch: Dispatch) => {
  try {
    dispatch(fetchUsersRequest())
    const res = await client.get<FetchUsersResponse>(URL_USERS, {
      params: {
        currentPage, perPage,sortBy, order, filterByName, filterByPartner, teamName, filterByRole       
      }
    })
    dispatch(fetchUsersSuccess(res.data))
  } catch (err: any) {
    const errorMessage = err.response?.data.error || err.toString()
    dispatch(fetchUsersFailure(errorMessage))
    openNotification(errorMessage, 'error')
  }
}

export const createTeamMember = (user: object, navigate: NavigateFunction) => async (
  dispatch: Dispatch
) => {
  try {
    dispatch(createTeamMemberRequest())
    await client.post(URL_USERS, user)
    dispatch(createTeamMemberSuccess())
    openNotification('New user has been created', 'success')
    navigate(RoutePaths.TEAM)
  } catch (err: any) {
    const errorMessage = err.response?.data.error || err.toString()
    dispatch(createTeamMemberFailure(errorMessage))
    openNotification(errorMessage, 'error')
  }
}

export const editTeamMember = (
  userId: number,
  updatedUser: object,
  navigate: NavigateFunction
) => async (dispatch: Dispatch) => {
  try {
    dispatch(editTeamMemberRequest())
    await client.put(`${URL_USERS}/${userId}`, updatedUser)
    dispatch(editTeamMemberSuccess())
    openNotification('User has been updated', 'success')
    navigate('/path-to-navigate')
  } catch (err: any) {
    const errorMessage = err.response?.data.error || err.toString()
    dispatch(editTeamMemberFailure(errorMessage))
    openNotification(errorMessage, 'error')
  }
}

export const deleteTeamMember = (userId: number,currentPage: number, perPage: number,sortBy: string, order: string,
                          filterByPartner: string | null,filterByName: string | null) => async (
  dispatch: Dispatch
) => {
  try {
    dispatch(deleteTeamMemberRequest())
    await client.delete(`${URL_USERS}/${userId}`)
    dispatch(deleteTeamMemberSuccess())
    store.dispatch(fetchUsers(currentPage,perPage,sortBy, order, filterByName, filterByPartner))
    openNotification('User has been deleted', 'success')
  } catch (err: any) {
    const errorMessage = err.response?.data.error || err.toString()
    dispatch(deleteTeamMemberFailure(errorMessage))
    openNotification(errorMessage, 'error')
  }
}

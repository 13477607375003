import styled from 'styled-components'

export const CardsContainer = styled.div<{ isCardGroup?: boolean }>`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 50px;
  margin-top: 30px;
  box-shadow: ${({ isCardGroup }) =>
    isCardGroup ? '0 20px 30px 0 rgba(0, 0, 0, 0.15)' : undefined};

  > div {
    ${({ isCardGroup }) =>
      isCardGroup
        ? undefined
        : `
        box-shadow: 0 20px 30px 0 rgba(0, 0, 0, 0.15);
        margin-right: 10px;
        border-radius: 5px;
    `}
  }

  > div:first-child {
    border-radius: ${({ isCardGroup }) =>
      isCardGroup ? '5px 0px 0px 5px' : '5px'};
  }
  > div:last-child {
    border-radius: ${({ isCardGroup }) =>
      isCardGroup ? '0px 5px 5px 0px' : '5px'};
    margin-right: 0px;
  }
`

export const Card = styled.div<{ colour?: string }>`
  min-height: 180px;
  flex-basis: 100px;
  flex-grow: 1;
  padding: 30px 20px;
  background: ${({ colour }) => colour};
  color: ${({ theme }) => theme.colours.primaryText};
  display: flex;

  i {
    font-size: 28px;
    margin-right: 10px;
  }
`

export const IconWrapper = styled.div`
  padding-right: 10px;
  font-size: 28px;
  margin-bottom: 10px; /* Adds space below the icon */
`;

export const CardContent = styled.div`
  flex-grow: 1;
`
export const CardTitle = styled.h3`
  font-size: 16px;
  color: ${({ theme }) => theme.colours.primaryText};
  line-height: 1.5;
  margin: 0 0 5px;
`
export const CardAmount = styled.p`
  font-size: 24px;
  line-height: 1.33;
  color: ${({ theme }) => theme.colours.primaryText};
  margin: 0;
  span {
    font-size: 12px;
    line-height: 1.67;
  }
`
export const CardSubContent = styled.div`
  margin-top: 10px;
  p {
    margin: 0px;
    color: ${({ theme }) => theme.colours.primaryText};
    opacity: 0.8;
  }
`

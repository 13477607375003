import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit'
import { Account } from '../schema/accounts'
import { RootState } from '../rootReducer'

type AccountsState = {
  loading: boolean
  error: string | null
  currentAccountId: number | null
  accounts: Account[]
  totalCount: number
}

const initialState: AccountsState = {
  loading: false,
  error: null,
  currentAccountId: null,
  accounts: [],
  totalCount: 0
}

function startLoading(state: AccountsState) {
  state.loading = true
  state.error = null
}

function loadingFailed(state: AccountsState, action: PayloadAction<string>) {
  state.loading = false
  state.error = action.payload
}

function loadingSucceeded(state: AccountsState) {
  state.loading = false
  state.error = null
}

function receiveAccount(state: AccountsState, action: PayloadAction<Account>) {
  const accountIndex = state.accounts.findIndex(
    (account: Account) => account.id === action.payload.id
  )
  if (accountIndex >= 0) {
    state.accounts.splice(accountIndex, 1, {
      ...state.accounts[accountIndex],
      ...action.payload
    })
  } else {
    state.accounts.push(action.payload)
  }
}

type SetApiKeyPayload = {
  id: number
  api_key: string
  mode: string
}

type SetSecretPayload = {
  id: number
  request_secret: string
  mode: string
}

type SetDisableAccount = {
  id: number
  disabled : boolean
}

type SetDisableSaaSSubscription = {
  id : number
  topup : boolean
  voucher : boolean
}

const accountsSlice = createSlice({
  name: 'accounts',
  initialState,
  reducers: {
    createAccountRequest: startLoading,
    createAccountFailure: loadingFailed,
    createAccountSuccess(state, action: PayloadAction<{account: Account, message: string}>) {
      state.loading = false
      state.error = null
      state.currentAccountId = action.payload.account.id
      state.accounts.push(action.payload.account)
    },
    setApiKeyRequest: startLoading,
    setApiKeyFailure: loadingFailed,
    setApiKeySuccess(state, action: PayloadAction<SetApiKeyPayload>) {
      state.loading = false
      state.error = null
      const account = state.accounts.find(
        (account: Account) => account.id === action.payload.id
      )

      if (account) {
        account.apiKey = action.payload.api_key
      }
    },
    setTemporaryDisableAccountRequest: startLoading,
    setTemporaryDisableAccountRequestFailure: loadingFailed,
    setTemporaryDisableAccountRequestSuccess(state, action: PayloadAction<SetDisableAccount>) {
      state.loading = false
      state.error = null
      const account = state.accounts.find(
        (account: Account) => account.id === action.payload.id
      )

      if (account) {
        account.disabled = action.payload.disabled
      }
    },
    setDisableSaaSSubscriptionAccountRequest: startLoading,
    setDisableSaaSSubscriptionAccountRequestSuccess(state, action: PayloadAction<SetDisableSaaSSubscription>) {
      state.loading = false
      state.error = null
      const account = state.accounts.find(
        (account: Account) => account.id === action.payload.id
      )
      if (account) {

        if("topup" in action.payload)
          account.disabledTopUpSaaSSubscription = action.payload.topup ? action.payload.topup : false
        if("voucher" in action.payload)
          account.disabledBrandSaaSSubscription = action.payload.voucher ? action.payload.voucher : false

      }
      
    },
    setDisableSaaSSubscriptionAccountRequestFailure : loadingFailed,
    setSaaSSubscriptionAccountRequest: startLoading,
    setSaaSSubscriptionAccountRequestSuccess(state, action: PayloadAction<Account>) {
      state.loading = false
      state.error = null
      const account = state.accounts.find(
        (account: Account) => account.id === action.payload.id
      )
      if (account) {
        account.SaaSSubscriptionData = action.payload.SaaSSubscriptionData
      }
    },
    setSaaSSubscriptionAccountRequestFailure : loadingFailed,
    setSecretRequest: startLoading,
    setSecretFailure: loadingFailed,
    setSecretSuccess(state, action: PayloadAction<SetSecretPayload>) {
      state.loading = false
      state.error = null
      const account = state.accounts.find(
        (account: Account) => account.id === action.payload.id
      )

      if (account) {
        account.requestSecret = action.payload.request_secret
      }
    },
    fetchAccountsRequest: startLoading,
    fetchAccountsFailure: loadingFailed,
    fetchAccountsSuccess(
      state,
      action: PayloadAction<{ result: Account[]; totalCount: number }>
    ) {
      state.loading = false
      state.error = null
      state.accounts = action.payload.result
      state.totalCount = action.payload.totalCount
    },
    fetchAccountByIdRequest: startLoading,
    fetchAccountByIdFailure: loadingFailed,
    fetchAccountByIdSuccess(state, action) {
      loadingSucceeded(state)
      receiveAccount(state, action)
    },
    updateAccountByIdRequest: startLoading,
    updateAccountByIdFailure: loadingFailed,
    updateAccountByIdSuccess(state, action) {
      loadingSucceeded(state)
      receiveAccount(state, action)
    },
    resetActiveAccount(state) {
      state.currentAccountId = null
    },
    setActiveAccount(state, action: PayloadAction<number>) {
      state.currentAccountId = action.payload
    },
    archivePartnerRequest: startLoading,
    archivePartnerRequestFailure: loadingFailed,
    archivePartnerSuccess(state, action: PayloadAction<number>) {
      state.loading = false
      state.error = null

      const account = state.accounts.filter(
        (account: Account) => account.id !== action.payload
      )

      state.accounts = account
    },
    deleteWebHookRequest : startLoading,
    deleteWebHookSuccess(state, action: PayloadAction<{accountId: number, webhookId: number}>) {
      state.loading = false
      state.error = null
      const account = state.accounts.find(
        (account: Account) => account.id === action.payload.accountId
      )

      if (account) {
        const webhooks = account.webhooks?.filter(wh=>{
          if(wh.id !== action.payload.webhookId) return wh
          return null
        })
        account.webhooks = webhooks?.filter(w=>w)
      }
    },
    deleteWebHookFailure: loadingFailed,
  }
})

const getAccounts = (state: RootState) => state.accounts.accounts
const getCurrentId = (state: RootState) => state.accounts.currentAccountId
const getIsLoading = (state: RootState) => state.accounts.loading
const getTotalCount = (state: RootState) => state.accounts.totalCount

export const accountSelectors = {
  getAccounts,
  getCurrentId,
  getCurrentAccount: createSelector(
    [getAccounts, getCurrentId],
    (accounts, id) => {
      const index = accounts.findIndex((account: Account) => account.id === id)
      return accounts[index]
    }
  ),
  getIsLoading,
  getTotalCount
}

export const {
  createAccountRequest,
  createAccountSuccess,
  createAccountFailure,
  setApiKeyRequest,
  setApiKeySuccess,
  setApiKeyFailure,
  setTemporaryDisableAccountRequest,
  setTemporaryDisableAccountRequestSuccess,
  setTemporaryDisableAccountRequestFailure,
  setDisableSaaSSubscriptionAccountRequest,
  setDisableSaaSSubscriptionAccountRequestSuccess,
  setDisableSaaSSubscriptionAccountRequestFailure,
  setSaaSSubscriptionAccountRequest,
  setSaaSSubscriptionAccountRequestSuccess,
  setSaaSSubscriptionAccountRequestFailure,
  setSecretRequest,
  setSecretSuccess,
  setSecretFailure,
  fetchAccountsRequest,
  fetchAccountsSuccess,
  fetchAccountsFailure,
  fetchAccountByIdRequest,
  fetchAccountByIdSuccess,
  fetchAccountByIdFailure,
  updateAccountByIdRequest,
  updateAccountByIdSuccess,
  updateAccountByIdFailure,
  resetActiveAccount,
  setActiveAccount,
  archivePartnerRequest,
  archivePartnerRequestFailure,
  archivePartnerSuccess,
  deleteWebHookRequest,
  deleteWebHookSuccess,
  deleteWebHookFailure
} = accountsSlice.actions

export const accountsReducer = accountsSlice.reducer

import React from 'react'
import { Link } from 'react-router-dom'

import { RoutePaths } from 'core/Router/routePaths'
import { Account } from 'common/store/schema/accounts'

import getCurrencyToLocale from 'common/utils/getCurrencyToLocale'

const columns = [
  {
    title: 'Name',
    dataIndex: 'organizationName',
    key: 'organizationName'
  },
  {
    title: 'Country',
    dataIndex: 'countryName',
    key: 'country'
  },
  {
    title: 'Balance',
    dataIndex: 'prefundedAmount',
    key: 'prefundedAmount',
    align: 'right' as 'right',
    render: (amount: number, record: Account) => {
      return getCurrencyToLocale(amount, record.prefundCurrency)
    }
  },
  {
    title: 'Balance (In Custody/Holding)',
    dataIndex: 'holdedPrefundedAmount',
    key: 'holdedPrefundedAmount',
    align: 'right' as 'right',
    render: (holdedPrefundedAmount: number, record: Account) => {
      return getCurrencyToLocale(holdedPrefundedAmount, record.prefundCurrency)
    }
  },
  {
    title: 'Commissions (Last 7 days)',
    dataIndex: 'commissions',
    key: 'commissions',
    align: 'right' as 'right',
    render: (commissions: number | undefined = 0, record: Account) => {
      return getCurrencyToLocale(commissions, record.prefundCurrency)
    }
  },
  {
    title: 'Action',
    key: 'action',
    align: 'center' as 'center',
    render: (text: string, record: Account) => {
      return <Link to={`${RoutePaths.EDIT_PARTNER}/${record.id}`}>Edit</Link>
    }
  }
]

export default columns

import { DefaultTheme } from 'styled-components'
import { baseColours } from './colours'

export const theme: DefaultTheme = {
  // font-size
  font: {
    xs: '11px',
    sm: '12px',
    md: '14px',
    lg: '20px',
    xl: '46px'
  },

  // colours
  colours: {
    bgLight: baseColours.white,
    bgDark: baseColours.grey1,
    heading: baseColours.grey4,
    formTitle: baseColours.blue3,
    icon: baseColours.grey4,
    inputText: baseColours.grey2,
    inputIcon: baseColours.grey3,
    inputBorder: baseColours.grey5,
    link: baseColours.blue2,
    primary: baseColours.blue1,
    primaryText: baseColours.white,
    buttonHover: baseColours.blue2,
    text: baseColours.grey3,
    textDark: baseColours.grey4,
    textLight: baseColours.grey6,
    errorBg: baseColours.red1,
    errorBorder: baseColours.red2,
    warning: baseColours.orange1,
    modalWarning: baseColours.orange2,
    danger: baseColours.red3,
    boxColour1: baseColours.blue4,
    boxColour2: baseColours.blue1,
    boxColour3: baseColours.blue5,
    boxColour4: baseColours.green1,
    boxColour5: baseColours.green2
  }
}

import { useEffect, useState } from 'react'
import { client } from 'common/api/client'

type PaginatedResponse = {
  data: {
    result: any[]
    totalCount: number
    perPage: number
    currentPage: number
  }
}

const useResourceList = <ResourceType>(
  url: string,
  currentPage: number,
  perPage: number,
  sortBy: string,
  order: string,
  organisationName?: string | null,
  actionName?: string | null,
  fromDate?: string | null,
  toDate?: string | null,
): {
  data: ResourceType[]
  isLoading: boolean
  error: boolean
  total: number
} => {
  const [data, setData] = useState<ResourceType[]>([])
  const [total, setTotal] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(false)

  useEffect(() => {
    setError(false)
    setIsLoading(true)
    const fetchData = async () => {
      try {
        const response: PaginatedResponse = await client.get(url, {
          params: {
            currentPage,
            perPage, sortBy, order, organisationName, actionName, fromDate, toDate
          }
        })
        setData(response.data.result)
        setTotal(response.data.totalCount)
      } catch (error) {
        setError(true)
      }
      setIsLoading(false)
    }

    fetchData()
  }, [url, currentPage, perPage, sortBy, order, organisationName, actionName, fromDate, toDate])

  return { data, isLoading, error, total }
}

export default useResourceList

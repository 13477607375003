import ConfigureForm from './ConfigureForm'
import { connect } from 'react-redux'

import { RootState } from 'common/store/rootReducer'
import { appSelectors } from 'common/store/app/slice'

const mapStateToProps = (state: RootState) => {
  return {
    currencyList: appSelectors.getCurrencies(state)
  }
}

export default connect(mapStateToProps)(ConfigureForm)

import React, { useEffect, useState } from 'react'
import Cards from '../Cards';
import { openNotification } from 'common/components/openNotification';
import { useNavigate } from 'react-router';
import { brandType, fetchBrandDetails, purchaseBrand } from '../fetchRedeem';

const Redeem = (props: any) => {

  const [brand,setBrand] = useState<Array<brandType>>([]);
  const [isLoading,setIsLoading] = useState(false);
  const [canConfirmPurchase] = useState(true);
  let history = useNavigate();

  useEffect(() => {
    try{
       
      const fetchData = async() => {
        // You can await here
        const brand: brandType = await fetchBrandDetails(props.match.params.productId)
        console.log(brand)
        setBrand([brand])
        setIsLoading(false)
      } 
      setIsLoading(true)
      fetchData()
    }catch(e){
      setIsLoading(false)
    }
  }, [props.match.params.productId ])

  const confirmPurchaseHandler = async() => {
    const data = await purchaseBrand(brand[0].productId,+props.match.params.denomination)
    if(Object.keys(data).length > 0 && data.status === "pending")
      history(`/redeem/brand-details/purchase/${data.orderId}`)
    else
      openNotification(data.data.error,'error')
  }

  return (
    <Cards data={brand} confirmPurchaseHandler={confirmPurchaseHandler} canConfirmPurchase={canConfirmPurchase} 
          denominationValue={props.match.params.denomination} isLoading={isLoading} />
  )
}

export default Redeem

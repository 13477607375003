import * as yup from 'yup'
import { passwordSchema } from 'common/utils/validationSchemas'

export default yup.object().shape({
  verificationCode: yup.number().required(),
  newPassword: passwordSchema,
  passwordConfirm: yup
    .string()
    .oneOf([yup.ref('newPassword'), null], "Passwords don't match")
    .required('Please re-type your new password')
})

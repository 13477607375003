import { client } from 'common/api/client'
import { openNotification } from 'common/components/openNotification'
import { useCallback, useEffect, useState } from 'react'

export interface PartnerErrorLog {
  dateAt: string
  path: string
  errorMessage: string
}

interface Response {
  data: PartnerErrorLog[]
  totalCount: number
  currentPage: number
  perPage: number
}

const useFetchPartnerErrorLogs = () => {
  const [data, setData] = useState<Response>()
  const [loading, setLoading] = useState(true)
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(10)
  const [order, setOrder] = useState<'ASC' | 'DESC'>('DESC')

  const fetch = useCallback(() => {
    setLoading(true)
    client
      .get<Response>('/me/error-logs', {
        params: {
          page,
          perPage,
          order,
        },
      })
      .then((r) => {
        setData(r.data)
      })
      .catch((e) => {
        openNotification(e.response?.data?.error, 'error')
      })
      .finally(() => {
        setLoading(false)
      })
  }, [page, perPage, order])

  useEffect(() => {
    fetch()
  }, [fetch])

  return {
    data,
    loading,
    page,
    perPage,
    setPage,
    setPerPage,
    order,
    setOrder,
    refetch: fetch,
  }
}

export default useFetchPartnerErrorLogs

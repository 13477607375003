export const URL_LOGIN = '/login'
export const URL_FORGOT_PASSWORD = '/forgot-password'
export const URL_CONFIRM_PASSWORD = '/confirm-password'
export const URL_CHANGE_PASSWORD = '/change-password'
export const URL_ACCOUNTS = '/accounts'
export const URL_ME = '/me'
export const URL_FETCH_BRANDS = '/brands/exportallbrandscatalog'
export const URL_PARTNER_FETCH_BRANDS = '/me/brands/exportallbrandscatalog'
export const URL_GENERATE_API_KEYS = (id: number) =>
`/accounts/${id}/generate-api-keys`
export const URL_USERS = '/users'
export const URL_APPLICATION_DATA = '/application-data'
export const URL_HISTORY = '/history'
export const URL_ADD_FUNDS = (id: number) => `/accounts/${id}/add-funds`
export const URL_ARCHIVE_PARTNER = (id: number) => `/accounts/${id}/archive-partner`
export const URL_DASHBOARD = '/dashboard'
export const URL_BRAND_LIST = (countryName: string,currentPage: number) => `/brands/country/${countryName}?currentPage=${currentPage}`
export const URL_BRAND_DETAILS = (id: string,currency: string) => `/brands/${id}?currency=${currency}`
export const URL_BRAND_PURCHASE = '/purchase?'
export const URL_BRAND_PURCHASE_STATUS = (orderId: string) => `/transaction/${orderId}`
export const URL_EXCLUSION_MAPPED_LIST = '/brandList'
export const URL_BRAND_LIST_PER_COUNTRY = (countryName: string) => `/listBrands/${countryName}`
export const URL_UPDATE_PRODUCT_LIST = () => `/enqueueBrandMappedExcl`
export const URL_ADD_EXCLUSION_MAPPED = '/brandListMapExcl'
export const URL_AGGREGATORS = `/aggregators`
export const URL_CATALOG_AUDIT = `/catalog-updates`
export const URL_UPDATE_AGGREGATOR = (id: number) => `/aggregator/${id}`
export const URL_DISABLE_SAAS_MODEL_FOR_ACCOUNT = (id: number) => `/accounts/${id}/saas-subscription`
export const URL_CREATE_UPDATE_SAAS_MODEL_FOR_ACCOUNT = (id: number,subscriptionGroup: string) => `/account/${id}/create/saas-subscription/${subscriptionGroup}`
export const URL_ACCOUNTS_WEBHOOKS = (id: number,accountId: number) => `/accounts/${accountId}/webhooks/${id}`
export const URL_GET_ERROR_LOGS = `/accounts/error-logs`
export const URL_CONFIGURATION_API = `/partnerAccount`
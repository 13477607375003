import { createGlobalStyle } from 'styled-components'

export default createGlobalStyle`
  body {
    font-family: "Montserrat", Lato, sans-serif;
    color: #000;

    .ant-modal-mask {
      background:${props => props.theme.colours.bgDark};
      opacity: 0.8;
    }

    .ant-modal-content {
      box-shadow:  0 2px 8px 0 rgba(0, 0, 0, 0.15);
    }

    .ant-modal-close {
      color: #000;
      top: -40px;
      right: -20px;
    }
  }

  p {
    color: ${props => props.theme.colours.text};
  }

  a {
    color: ${props => props.theme.colours.link};
  }



`

import React from 'react'
import { DateTime } from 'luxon'
import {
  Balance,
  BalanceHeading,
  Commissions,
  CommissionsHeading,
  Detail,
  Group
} from './styledComponents'
import getCurrencyToLocale from 'common/utils/getCurrencyToLocale'

type Props = {
  balance?: number
  className?: string
  commissions?: number
  currency?: string
  lastTopUp?: Date
  holdedPrefundedAmount?: number
}

const FinancialDetails: React.FC<Props> = ({
  balance = 0,
  className,
  commissions = 0,
  holdedPrefundedAmount = 0, 
  currency = 'USD',
  lastTopUp
}) => {
  return (
    <div className={className}>
      <Group>
        <BalanceHeading>Balance</BalanceHeading>
        <Balance>{getCurrencyToLocale(balance, currency)}</Balance>
        {lastTopUp && (
          <Detail>
            Last top-up was on {DateTime.fromJSDate(lastTopUp).toFormat('LLLL dd, yyyy')}.
          </Detail>
        )}
      </Group>
      <Group>
        <CommissionsHeading>Withheld Balance ( Balance in Phaze Custody)</CommissionsHeading>
        <Commissions>{getCurrencyToLocale(holdedPrefundedAmount, currency)}</Commissions>
        <Detail>This is the balance we hold of partner where end user has not redeemed the products.</Detail>
      </Group>
      <Group>
        <CommissionsHeading>Commissions</CommissionsHeading>
        <Commissions>{getCurrencyToLocale(commissions, currency)}</Commissions>
        <Detail>Earned in the last 7 days</Detail>
      </Group>
    </div>
  )
}

export default FinancialDetails

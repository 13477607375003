import React from 'react'
import { Pagination as PaginationBase } from 'antd'
import { Select } from 'common/components/Select'
import { PaginationConfig } from 'antd/lib/pagination'
import { PaginationContainer } from './styledComponents'
const { Option } = Select

interface PaginationProps {
  onPageChange: (page: number) => void
  onPageSizeChange: (value: number) => void
  pagination: PaginationConfig
}

const Pagination: React.FC<PaginationProps> = ({
  onPageChange,
  onPageSizeChange,
  pagination
}) => {
  return (
    <PaginationContainer>
      <Select
        defaultValue={10}
        style={{ width: 120 }}
        onChange={value => onPageSizeChange(value as number)}
      >
        <Option value={10}>Display 10</Option>
        <Option value={25}>Display 25</Option>
        <Option value={50}>Display 50</Option>
      </Select>
      <PaginationBase {...pagination} onChange={onPageChange} />
    </PaginationContainer>
  )
}

export default Pagination

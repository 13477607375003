import { List } from 'antd'
import React, { FC } from 'react'

interface IEmailListProps {
  emails: string[]
}

const EmailList: FC<IEmailListProps> = ({ emails }) => {
  return (
    <div
      id='scrollableDiv'
      style={{
        height: 200,
        overflow: 'auto',
        padding: '0 16px',
        border: '1px solid rgba(140, 140, 140, 0.35)',
      }}
    >
      <List
        dataSource={emails}
        renderItem={(item: string) => <List.Item key={item}>{item}</List.Item>}
      />
    </div>
  )
}

export default EmailList

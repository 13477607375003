import { combineReducers } from '@reduxjs/toolkit'
import { authReducer } from './auth'
import { accountsReducer } from './accounts'
import { teamReducer } from './team'
import { appReducer } from './app'
import { productListReducer } from './brandListFromType'
import { aggregatorReducer } from './aggregators'
import { brandsDeltaReducer } from './brandData'
import { errorLogReducer } from './errorLogs'
import { partnerAdminReducer } from './partnerAdmin'

export const rootReducer = combineReducers({
  auth: authReducer,
  accounts: accountsReducer,
  team: teamReducer,
  app: appReducer,
  productList: productListReducer,
  aggregator: aggregatorReducer,
  brands: brandsDeltaReducer,
  errorLogs: errorLogReducer,
  partnerAdmin: partnerAdminReducer,
})

export type RootState = ReturnType<typeof rootReducer>

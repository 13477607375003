import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { createTeamMember } from 'common/store/team/actions';
import AddTeamMember from './AddTeamMember';

const AddTeamMemberContainer: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmit = (values: object) => {
    dispatch(createTeamMember(values, navigate));
  };

  return <AddTeamMember onSubmit={handleSubmit} />;
};

export default AddTeamMemberContainer;

import { client } from 'common/api/client'
import { openNotification } from 'common/components/openNotification'
import { useEffect, useState } from 'react'

export interface BroadcastHistory {
  id: number
  templateName: string
  subject: string
  body: string
  sentToEmails: string
  failedEmails: string
  created_at: string
  updated_at: string
}

interface Response {
  histories: BroadcastHistory[]
  count: number
  totalPagesCount: number
  page: number
}

const useBroadcastHistories = () => {
  const [data, setData] = useState<Response>()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)

  const fetchBroadcastHistories = () => {
    setLoading(true)
    client
      .get<Response>('/broadcast-histories', {
        params: {
          page,
          limit
        }
      })
      .then(r => {
        setData(r.data)
      })
      .catch(e => {
        const error = e.response?.data?.error || e.message

        setError(error)
        openNotification(error, 'error')
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    fetchBroadcastHistories()
  }, [page, limit]) // eslint-disable-line react-hooks/exhaustive-deps

  return {
    data,
    loading,
    error,
    page,
    setPage,
    limit,
    setLimit,
    refetch: fetchBroadcastHistories
  }
}

export default useBroadcastHistories

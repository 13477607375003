import { useCallback, useState } from 'react'
import { client } from 'common/api/client'
import FileDownload from 'js-file-download'
import { openNotification } from 'common/components/openNotification'

interface DownloadCSVParams {
  fromDate: string;
  toDate: string;
  filterByBrand?: string;
  filterByOrderId?: string;
  filterByStatus?: string;
  filterByPartnerName?: string;
  filterByClientProductId?: string;
  filterBySupplierProductId?: string;
  filterByTransactionType?: string;
}
const useDownloadCSV = (url: string, organizationName?:string, name? : string) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [fname] = useState<string>('_transaction_history.csv')

  const downloadCSVcallback = useCallback(
    (fromDate: string | null, toDate: string | null,
      filterByBrand: string = '',
      filterByOrderId: string = '',
      filterByStatus: string = '',
      filterByPartnerName: string = '',
      filterByClientProductId: string = '',
      filterBySupplierProductId: string = '',
      filterByTransactionType: string = '') => {
      setIsLoading(true)

      const fetchData = async (url: string) => {
        try {
          const response = await client.get<string | ArrayBuffer | ArrayBufferView | Blob>(url, {
            params: {
              fromDate: fromDate,
              toDate: toDate,
              filterByBrand,
              filterByOrderId,
              filterByStatus,
              filterByPartnerName,
              filterByClientProductId,
              filterBySupplierProductId,
              filterByTransactionType
            }
          })

          let fileName= organizationName + fname
          if(name)
            fileName = organizationName + name

          FileDownload(response.data, fileName)
        } catch (err: any) {
          const errorMessage = err.response?.data.error || err.toString()
          openNotification(errorMessage, 'error')
        }
        setIsLoading(false)
      }

      fetchData(url)
    },
    [organizationName, url, fname, name]
  )

  return {
    isLoading,
    downloadCSVcallback
  }
}

export default useDownloadCSV

import React from 'react';
import { createRoot } from 'react-dom/client';
import 'antd/dist/reset.css';

import App from './core/App';
import * as serviceWorker from './serviceWorker';
import { ThemeProvider } from 'styled-components';
import { Provider } from 'react-redux';
import { store } from './common/store';
import { BrowserRouter as Router } from 'react-router-dom';
import GlobalStyles from './common/styles/globalStyles';
import { theme } from 'common/styles/theme';
import { PhazeEnvProvider } from 'common/contexts/phazeEnvContext';

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <PhazeEnvProvider>
    <ThemeProvider theme={theme}>
      <Router>
        <Provider store={store}>
          <GlobalStyles />
          <App />
        </Provider>
      </Router>
    </ThemeProvider>
  </PhazeEnvProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

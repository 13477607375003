import { connect } from 'react-redux'
import Partners from './Partners'
import { fetchAccounts } from 'common/store/accounts/actions'
import { accountSelectors } from 'common/store/accounts/slice'
import { RootState } from 'common/store/rootReducer'
import { appSelectors } from 'common/store/app/slice'

const mapStateToProps = (state: RootState) => {
  return {
    role: state.auth.role,
    countryList: appSelectors.getCountries(state),
    accounts: accountSelectors.getAccounts(state),
    isLoading: accountSelectors.getIsLoading(state),
    totalNumberOfAccounts: accountSelectors.getTotalCount(state)
  }
}

const mapDispatchToProps = {
  fetchAccounts
}

export default connect(mapStateToProps, mapDispatchToProps)(Partners)

import { client } from 'common/api/client'
import { openNotification } from 'common/components/openNotification'
import { useEffect, useState } from 'react'

export interface Template {
  id: number
  name: string
  subject: string
  body: string
}

type Response = {
  templates: Template[]
  count: number
}

const useFetchTemplates = () => {
  const [templates, setTemplates] = useState<Template[]>([])
  const [count, setCount] = useState<number>()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')

  const fetch = () => {
    setLoading(true)
    client
      .get<Response>('/email-templates')
      .then((res) => {
        setTemplates(res.data.templates)
        setCount(res.data.count)
      })
      .catch((e) => {
        const error = e.response?.data?.message || 'Something went wrong'

        openNotification(error, 'error')
        setError(error)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const createNewTemplate = async (template: Omit<Template, 'id'>) => {
    if (!template.name || !template.subject || !template.body) {
      openNotification('Please fill all fields', 'error')
      throw Error()
    }

    setLoading(true)
    await client
      .post('/email-templates', template)
      .then(fetch)
      .catch((e) => {
        const error = e.response?.data?.message || 'Something went wrong'

        openNotification(error, 'error')
        setError(error)
        setLoading(false)
      })
  }

  const deleteTemplate = (id: number) => {
    if (!window.confirm('Are you sure?')) return
    setLoading(true)
    client
      .delete(`/email-templates/${id}`)
      .then(fetch)
      .catch((e) => {
        const error = e.response?.data?.message || 'Something went wrong'

        openNotification(error, 'error')
        setError(error)
        setLoading(false)
      })
  }

  const updateTemplate = async (template: Template) => {
    if (!template.name || !template.subject || !template.body || !template.id) {
      openNotification('Please fill all fields', 'error')
      return
    }

    setLoading(true)
    await client
      .put(`/email-templates/${template.id}`, {
        name: template.name,
        subject: template.subject,
        body: template.body,
      })
      .then(() => {
        openNotification('Template updated successfully', 'success')
        fetch()
      })
      .catch((e) => {
        const error = e.response?.data?.message || 'Something went wrong'

        openNotification(error, 'error')
        setError(error)
        setLoading(false)
      })
  }

  useEffect(() => {
    fetch()
  }, [])

  return {
    templates,
    count,
    loading,
    error,
    createNewTemplate,
    refetch: fetch,
    deleteTemplate,
    updateTemplate,
  }
}

export default useFetchTemplates

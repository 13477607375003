import styled from 'styled-components'
import { Button } from 'common/components/Button'

export const Container = styled.div`
  margin: 0 50px;
`

export const CurrentEnv = styled.span`
  color: ${props => props.theme.colours.primary};
  font-weight: 600;
`

export const Text = styled.p`
  margin: 0;
`
//TO-DO: Update
//@ts-ignore
export const LinkButton = styled(Button)`
  margin: 0 0 0 20px;
`

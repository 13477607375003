import {
  loginRequest,
  loginSuccess,
  loginFailure,
  resetPasswordRequest,
  resetPasswordSuccess,
  resetPasswordFailure,
  confirmPasswordRequest,
  confirmPasswordSuccess,
  confirmPasswordFailure,
  changeTemporaryPasswordFailure,
  changeTemporaryPasswordRequest,
  changeTemporaryPasswordSuccess,
  userInfoRequest,
  userInfoSuccess,
  userInfoFailure,
  logoutRequest,
  UserInfoPayload
} from './slice'
import { Dispatch } from '@reduxjs/toolkit'
import { client } from 'common/api/client'
import {
  URL_LOGIN,
  URL_FORGOT_PASSWORD,
  URL_CONFIRM_PASSWORD,
  URL_CHANGE_PASSWORD,
  URL_ME
} from 'common/api/paths'
import { openNotification } from 'common/components/openNotification'
import { getTokenExpiration } from 'common/utils/common'
import { RoutePaths } from 'core/Router/routePaths'
import { store } from '..'
import { useNavigate } from 'react-router-dom'

interface LoginResponse {
  token: string
  resetPassword?: boolean
}

export const login = (
  email: string,
  password: string,
  navigate: ReturnType<typeof useNavigate>
) => async (dispatch: Dispatch) => {
  try {
    dispatch(loginRequest())
    const res = await client.post<LoginResponse>(URL_LOGIN, { email, password })
    
    if (res.status === 200) {
      if (res.data.resetPassword) {
        return navigate(
          `${RoutePaths.CHANGE_TEMPORARY_PASSWORD}?email=${email}`,
          { replace: true }
        )
      }

      dispatch(
        loginSuccess({
          token: res.data.token,
          tokenExpiration: getTokenExpiration(res.data.token)
        })
      )

      localStorage.setItem('token', res.data.token)
      store.dispatch(getUserInfo() as any)
      navigate(RoutePaths.DASHBOARD, { replace: true })
    }
  } catch (err: any) {
    if (err.response?.status === 405) {
      dispatch(loginFailure('IP Address Blocked'))
      openNotification('IP Address Blocked', 'error')
    } else {
      dispatch(loginFailure('Incorrect login or password'))
      openNotification('Incorrect login or password', 'error')
    }
  }
}

export const resetPassword = (email: string) => async (dispatch: Dispatch) => {
  try {
    dispatch(resetPasswordRequest())
    const res = await client.post(URL_FORGOT_PASSWORD, { email })

    if (res.status === 200) {
      dispatch(resetPasswordSuccess())
    }
  } catch (err: any) {
    const errorMessage = err.response?.data.error || err.toString()
    dispatch(resetPasswordFailure(errorMessage))
    openNotification(errorMessage, 'error')
  }
}

export const confirmPassword = (
  email: string,
  newPassword: string,
  verificationCode: string,
  navigate: ReturnType<typeof useNavigate>
) => async (dispatch: Dispatch) => {
  try {
    dispatch(confirmPasswordRequest())
    await client.post(URL_CONFIRM_PASSWORD, {
      email,
      newPassword,
      verificationCode
    })

    dispatch(confirmPasswordSuccess())
    navigate(RoutePaths.LOGIN, { replace: true })
    openNotification('Your password has been updated', 'success')
  } catch (err: any) {
    const errorMessage = err.response?.data.error || err.toString()
    dispatch(confirmPasswordFailure(errorMessage))
    openNotification(errorMessage, 'error')
  }
}

export const changeTemporaryPassword = (
  email: string,
  oldPassword: string,
  newPassword: string,
  navigate: ReturnType<typeof useNavigate>
) => async (dispatch: Dispatch) => {
  try {
    dispatch(changeTemporaryPasswordRequest())
    await client.post(URL_CHANGE_PASSWORD, {
      email,
      password: oldPassword,
      newPassword
    })

    dispatch(changeTemporaryPasswordSuccess())
    openNotification('Your password has been updated', 'success')
    navigate(RoutePaths.LOGIN, { replace: true })
  } catch (err: any) {
    const errorMessage = err.response?.data.error || err.toString()
    dispatch(changeTemporaryPasswordFailure(errorMessage))
    openNotification(errorMessage, 'error')
  }
}

export const logout = (navigate: ReturnType<typeof useNavigate>) => async (dispatch: Dispatch) => {
  localStorage.removeItem('token')
  dispatch(logoutRequest())
  navigate(RoutePaths.LOGIN, { replace: true })
}

export const getUserInfo = () => async (dispatch: Dispatch) => {
  try {
    dispatch(userInfoRequest())
    const res = await client.get<UserInfoPayload>(URL_ME)
    dispatch(
      userInfoSuccess({
        email: res.data.email,
        role: res.data.role,
        organizationName: res.data.organizationName,
        reconcilePartner: res.data.reconcilePartner,
      })
    )
  } catch (err: any) {
    const errorMessage = err.response?.data.error || err.toString()
    dispatch(userInfoFailure(errorMessage))
    openNotification(errorMessage, 'error')
  }
}
